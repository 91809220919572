define('connect-app/router', ['exports', 'ember', 'connect-app/config/environment', 'connect-app/route-config'], function (exports, _ember, _connectAppConfigEnvironment, _connectAppRouteConfig) {
    "using strict";

    var Router = _ember['default'].Router.extend({
        location: _connectAppConfigEnvironment['default'].locationType
    });

    Router.map(function () {
        var _this = this;

        _.forEach(_connectAppRouteConfig['default'], function (component) {
            component.params = component.params || [];
            var link = '';

            if (_.has(component, 'parents')) {
                var segments = _.clone(component.parents);
                segments.forEach(function (segment) {
                    return Array.prototype.push.apply(component.params, _connectAppRouteConfig['default'][segment].params);
                });
                if (!_.isUndefined(segments)) segments.push(component.url);
                link = segments.map(function (segment) {
                    return _.has(_connectAppRouteConfig['default'], segment) ? _connectAppRouteConfig['default'][segment].url : segment;
                }).join('/');
            }

            _this.route(component.component, { path: link });
        });
    });

    exports['default'] = Router;
});