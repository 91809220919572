define('connect-app/components/pa-batches-list', ['exports', 'ember', 'connect-app/initializers/model-loader', 'connect-app/libs/utils'], function (exports, _ember, _connectAppInitializersModelLoader, _connectAppLibsUtils) {
    exports['default'] = _ember['default'].Component.extend({
        project_id: null,
        act_code_search: '',
        search_too_short: '',

        didReceiveAttrs: function didReceiveAttrs() {
            this._super.apply(this, arguments);
            _connectAppInitializersModelLoader.DB.set('pabatches.criteria', { 'project_id': this.get('project_id'), 'device_prefix': '' });
        },

        project: (0, _connectAppLibsUtils.computed_model)('PAProjects', 'project_id'),

        batches: _ember['default'].computed('DB.pabatches.[]', 'project', function () {
            var project = this.get('project');

            if (_.isUndefined(project)) return [];

            var batches = _connectAppInitializersModelLoader.DB.pabatches.toArray();
            batches.forEach(function (batch) {
                var num_devices = batch.get('licenced_devices');

                batch.setProperties({
                    text_label: (0, _connectAppLibsUtils.human_readable_date)(batch.get('time_generated')) + ' [' + num_devices + ' ' + (0, _connectAppLibsUtils.translate)(num_devices == 1 ? 'pa_batches_list.device' : 'pa_batches_list.devices') + ']',
                    extras: batch.get('user_name') + ' (' + batch.get('partner_name') + ')',
                    icon: 'i_save',
                    download_url: 'GetPABatch?project_id=' + project.get('project_id') + '&batch_id=' + batch.get('batch_id')
                });
            });

            return _.sortBy(batches, function (batch) {
                return -batch.get('time_generated');
            });
        }),

        search_observer: _ember['default'].observer('act_code_search', function () {
            var device_prefix = this.get('act_code_search');
            var prev_prefix = _connectAppInitializersModelLoader.DB.pabatches.get('criteria.device_prefix');

            if (device_prefix.length > 2 && prev_prefix !== device_prefix) this._set_prefix(device_prefix);else if (device_prefix.length < 3 && prev_prefix !== '') this._set_prefix('');

            this.set('search_too_short', device_prefix.length > 0 && device_prefix.length < 3);
        }),

        _set_prefix: function _set_prefix(prefix) {
            _connectAppInitializersModelLoader.DB.pabatches.set('criteria', { 'project_id': this.get('project_id'), 'device_prefix': prefix });
        },

        actions: {
            click_batch: function click_batch(batch) {
                (0, _connectAppLibsUtils.download_binary)(batch.download_url);
            }
        }
    });
});