define('connect-app/libs/mireo-data', ['exports', 'ember', 'connect-app/libs/auth', 'connect-app/libs/guid-gen'], function (exports, _ember, _connectAppLibsAuth, _connectAppLibsGuidGen) {
	exports.m_ajax = m_ajax;
	exports.attr = attr;
	exports.belongs_to = belongs_to;
	exports.has_many = has_many;
	exports.table = table;
	exports.schema = schema;
	var Tables = _ember['default'].Namespace.create();
	exports.Tables = Tables;
	var Schemas = _ember['default'].Namespace.create();

	exports.Schemas = Schemas;

	function m_ajax(req_type, root_url, method, rest_args, url_params, body_params, handle_type) {

		return _connectAppLibsAuth['default'].refresh_token().then(function () {

			var url = _connectAppLibsAuth['default'].get('app_origin') + '/' + method;
			var up = $.param(url_params, true);
			if (handle_type == 'fun') {
				var args = _.isArray(rest_args) ? rest_args.join('&') : rest_args;
				if (args) url = url + '?' + args;
				if (up) url = url + '&' + up;
			} else {
				var args = _.isArray(rest_args) ? rest_args.join('/') : rest_args;
				if (args) url = url + args;
				if (up) url = url + '?' + up;
			}

			url += $.param(url_params, true);

			var settings = {
				url: url,
				type: req_type,
				dataType: null,
				crossDomain: true,
				headers: {
					Authorization: 'Bearer ' + _connectAppLibsAuth['default'].get('token')
				}
			};

			if (body_params) {
				// setting application/json as content-type triggers OPTIONS CORS preflight
				settings.contentType = "text/plain; charset=utf-8";
				settings.data = body_params;
			}

			return new _ember['default'].RSVP.Promise(function (resolve, reject) {

				settings.success = function (json) {
					_ember['default'].run(null, resolve, json);
				};

				settings.error = function (jqXHR, textStatus, errorThrown) {
					console.log('jqXHR error ', method);
					if (jqXHR && typeof jqXHR === 'object') {
						jqXHR.then = null;
						if (jqXHR.status == 502) {
							_ember['default'].run.join(null, reject, 'ERR_BAD_GATEWAY');
							return;
						}
					}
					if (reject) _ember['default'].run(null, reject, jqXHR);
				};

				_ember['default'].$.ajax(settings);
			});
		})['catch'](function (err) {
			return console.log('failed to refresh token ', method);
		});
	}

	/* 'Create[s]/POST', 'Get[s]s/GET', 'Update[s]/PUT', 'Delete[s]/DELETE' */

	function m_ajax_create(schema_name, json_data) {
		return m_ajax('POST', null, 'FleetWebApi/Create' + schema_name, null, {}, json_data, 'rest');
	}

	function m_ajax_update(schema_name, primary_key, json_data) {
		return m_ajax('PUT', null, 'FleetWebApi/Update' + schema_name, primary_key, {}, json_data, 'rest');
	}

	function m_ajax_delete(schema_name, primary_key) {
		return m_ajax('DELETE', null, 'FleetWebApi/Delete' + schema_name, primary_key, {}, null, 'rest');
	}

	function null_promise() {
		return new _ember['default'].RSVP.allSettled([]);
	}

	var MireoAjax = _ember['default'].Object.extend({
		req_type: 'GET',
		url_method: '',
		handle_type: 'rest',
		resolve_rest_args: function resolve_rest_args(ra) {
			var f = this.get('table.criteria');
			var handle_type = this.get('handle_type');
			if (handle_type == 'fun') {
				_.each(f, function (v, k) {
					ra = ra.replace('{' + k + '}', k + '=' + v);
				});
			} else {
				_.each(f, function (v, k) {
					ra = ra.replace('{' + k + '}', v);
				});
			}
			return ra;
		},
		ajax: function ajax(req_type, rest_args, url_params, body_params) {
			return m_ajax(req_type, this.get('root_url'), this.get('url_method'), rest_args, url_params, body_params, this.get('handle_type'));
		},
		_dispatch: function _dispatch(json) {
			if ($.isArray(json)) {
				var r = this.get('table.reload');
				if (r) r.call(this.get('table'), json);
				return;
			}
			_.each(json, function (v, k) {
				var tbl = table(k.classify());
				var r = tbl.get('reload');
				if (r) r.call(tbl, v);
			});
		}
	});

	var ManualAdapter = MireoAjax.extend({
		table: _ember['default'].K,
		url_params: {},

		_reload: _ember['default'].observer('table.criteria', function () {
			var r = this.get('table.reload');
			if (!$.isFunction(r)) return;
			var ra = this.get('rest_args');
			if (ra) {
				ra = this.resolve_rest_args(ra);
				if (ra.indexOf('{') != -1) return; /* mandatory args not specified */
			}
			this.get('table._content').clear();
			this.set('table._idhash', _ember['default'].Object.create());
			this.ajax('GET', ra, this.get('url_params')).then($.proxy(this._dispatch, this));
		})
	});

	exports.ManualAdapter = ManualAdapter;
	var PeriodicAdapter = MireoAjax.extend({
		interval: 1000,
		table: _ember['default'].K,
		url_params: {},

		_loop: function _loop(tt) {
			_ember['default'].run.later(this, this._reload, this.get('interval'));
		},

		_reload: _ember['default'].on('init', function () {
			var ra = this.get('rest_args');
			if (ra) {
				ra = this.resolve_rest_args(ra);
				if (ra.indexOf('{') != -1) {
					this._loop();
					return; /* mandatory args not specified */
				}
			}
			this.ajax('GET', ra, this.get('url_params')).then($.proxy(this._dispatch, this))['finally']($.proxy(this._loop, this));
		})
	});

	exports.PeriodicAdapter = PeriodicAdapter;
	var StampedAdapter = MireoAjax.extend({
		stamp_provider: null,
		stamp_fun: null,
		table: null,
		url_params: {},
		_current_stamp: null,
		_fetching: false,
		_on_error_timeout: 1000,

		_attach_observers: _ember['default'].observer('table', function () {
			if (!this.get('table')) return;
			var sp = table(this.get('stamp_provider')) || this.get('table');
			var sf = this.get('stamp_fun') || this.get('url_method');
			sp.addObserver(sf, this, '_reload');
			/* when stamp provider already has timestamp for 'sf' we need to trigger _reload */
			if (_.isNumber(sp.get(sf))) this._reload();
		}),

		_ra_observer: _ember['default'].observer('table.criteria', function () {
			if (!this.get('table')) return;
			this.get('table._content').clear();
			this.set('table._idhash', _ember['default'].Object.create());
			this.set('_current_stamp', null);
			var sp = table(this.get('stamp_provider')) || this.get('table');
			var sf = this.get('stamp_fun') || this.get('url_method');
			if (_.isNumber(sp.get(sf))) this._reload();
		}),

		_reload: function _reload() {
			var sp = table(this.get('stamp_provider'));
			var sf = this.get('stamp_fun') || this.get('url_method');
			var stamp = sp.get(sf);

			var cs = this.get('_current_stamp');
			if (cs == null) cs = 0;else if (cs > stamp) return;
			this.set('_current_stamp', stamp);

			if (this.get('_fetching') !== false) return;

			var rargs = _ember['default'].A();rargs.pushObject('stamp=' + cs);
			var ra = this.get('rest_args');
			if (ra) {
				ra = this.resolve_rest_args(ra);
				if (ra.indexOf('{') != -1) {
					return; /* mandatory args not specified */
				} else if (_.isArray(ra)) rargs.pushObjects(ra);else rargs.pushObject(ra);
			}

			this.set('_fetching', true);
			this.ajax('GET', rargs, this.get('url_params')).then($.proxy(function (stamp, json) {
				this._dispatch(json);
				this.set('_fetching', false);
				if (stamp < this.get('_current_stamp')) this._reload();
			}, this, this.get('_current_stamp')))['catch']($.proxy(function () {
				this.set('_fetching', false);
				_ember['default'].run.later(this, this._reload, this.get('_on_error_timeout'));
			}, this));
		},

		willDestroy: function willDestroy() {
			var sp = table(this.get('stamp_provider')) || this.get('table');
			var sf = this.get('stamp_fun') || this.get('url_method');
			sp.removeObserver(sf, this, '_reload');
		}
	});

	exports.StampedAdapter = StampedAdapter;
	function pk2array(ob, pkdef) {
		if (_.isArray(pkdef)) {
			if (_.isFunction(ob.get)) return _.map(pkdef, function (k) {
				return ob.get('_' + ob.constructor.metaForProperty(k).dbfield);
			});
			return _.map(pkdef, function (k) {
				return ob[k];
			});
		}
		if (_.isFunction(ob.get)) {
			var field = ob.constructor.metaForProperty(pkdef);
			field = field && field.dbfield ? '_' + field.dbfield : pkdef;
			return [ob.get(field)];
		}
		return [ob[pkdef]];
	}

	function pk2val(ob, pkdef) {
		return pk2array(ob, pkdef).join('|');
	}

	function val2pk(ob, pkdef, pkob) {
		if (_.isArray(pkdef)) _.each(pkdef, function (k) {
			var field = ob.constructor.metaForProperty(k).dbfield;
			ob.set('_' + field, pkob[field]);
		});else {
			var field = ob.constructor.metaForProperty(pkdef).dbfield;
			ob.set('_' + field, pkob[field]);
		}
	}

	function pk2filter(ob, pkdef) {
		var rv = {};
		if (_.isArray(pkdef)) _.each(pkdef, function (k) {
			var field = '_' + ob.constructor.metaForProperty(k).dbfield;
			rv[field] = ob.get(field);
		});else {
			var field = '_' + ob.constructor.metaForProperty(pkdef).dbfield;
			rv[field] = ob.get(field);
		}
		return rv;
	}

	function has_attr(ob, k, attr) {
		if (!_.isFunction(ob.get)) return false;
		var coattrs = ob.get('_attrs');
		return coattrs && coattrs[k][attr];
	}

	function attr_opts(ob, k) {
		if (!_.isFunction(ob.get)) return false;
		var coattrs = ob.get('_attrs');
		return coattrs && coattrs[k];
	}

	var revert_attr = function revert_attr(meta) {
		if (this.get('_orig_' + meta.dbfield) !== undefined) this.set('_' + meta.dbfield, this.get('_orig_' + meta.dbfield));
		this.get_dirty_attrs().removeObject(meta.dbfield);
	};

	var clean_dirty_attr = function clean_dirty_attr(meta) {
		if (this.get('_orig_' + meta.dbfield) !== undefined) this.set('_orig_' + meta.dbfield, undefined);
		this.get_dirty_attrs().removeObject(meta.dbfield);
	};

	var set_dirty_attr = function set_dirty_attr(meta) {
		var nv = this.get('_' + meta.dbfield);
		this.set('_orig_' + meta.dbfield, _.isUndefined(nv) ? null : nv);
		this.get_dirty_attrs().addObject(meta.dbfield);
	};

	var create_attr = function create_attr(name, dbfield) {
		return _ember['default'].computed('_' + dbfield, {
			get: function get(key) {
				var field = this.constructor.metaForProperty(key).dbfield;
				return this.get('_' + field);
			},
			set: function set(key, value) {
				var prev_val = this.get(key);
				if (prev_val == value) return value;
				var field = this.constructor.metaForProperty(key).dbfield;
				if (this.get('_orig_' + field) === undefined) this.set('_orig_' + field, prev_val);
				this.set('_' + field, value);
				this.get_dirty_attrs().addObject(field);
				return value;
			}
		}).meta({
			dbfield: dbfield, revert: revert_attr,
			clean_dirty: clean_dirty_attr, set_dirty: set_dirty_attr
		});
	};

	var create_belongs_to = function create_belongs_to(dbfield, child_table) {
		var dep = ['__', child_table, '.[]'].join('');
		return _ember['default'].computed(dep, {
			get: function get(key) {
				var meta = this.constructor.metaForProperty(key);
				var chtbl = meta.child_table;
				var field = meta.dbfield;
				var rv = field ? this.get('_' + field) : this.pk_value();
				return rv ? table(chtbl).row_by_id(rv) : undefined;
			},
			set: function set(key, value) {
				var meta = this.constructor.metaForProperty(key);
				var field = meta.dbfield;
				if (field === undefined) return value;
				var prev_vid = this.get('_' + field);
				var new_vid = value ? value.pk_value() : null;
				if (prev_vid == new_vid) return value;
				if (this.get('_orig_' + field) === undefined) this.set('_orig_' + field, prev_vid);
				this.set('_' + field, new_vid);
				this.get_dirty_attrs().addObject(field);
				return value;
			}
		}).meta({
			child_table: child_table, dbfield: dbfield, revert: revert_attr,
			clean_dirty: clean_dirty_attr, set_dirty: set_dirty_attr
		});
	};

	var create_poly_belongs_to = function create_poly_belongs_to(dbfield, polyopts) {
		var dep_args = _.map(polyopts.types, function (v) {
			return '__' + v + '.[]';
		});
		dep_args.push({
			get: function get(key) {
				var meta = this.constructor.metaForProperty(key);
				var myval = this.get('_' + meta.dbfield);
				var mytype = meta.types[this.get('_' + meta.type_field)];
				var child_table = table(mytype);
				return child_table ? child_table.row_by_id(myval) : undefined;
			},
			set: function set(key, val) {
				var meta = this.constructor.metaForProperty(key);
				var field = meta.dbfield;
				var prev_vid = this.get('_' + field);
				var new_vid = val ? val.pk_value() : null;
				if (prev_vid == new_vid) return val;
				if (this.get('_orig_' + field) === undefined) {
					this.set('_orig_' + field, prev_vid);
					this.set('_orig_' + meta.type_field, this.get('_' + meta.type_field));
				}
				var myval = this.set('_' + field, new_vid);
				var type = _.find(_.invert(meta.types), function (t, k) {
					return val instanceof schema(table(k).get('row_type'));
				});
				this.set('_' + meta.type_field, type);
				this.get_dirty_attrs().addObject(field);
				return val;
			}
		});
		return _ember['default'].computed.apply(undefined, dep_args).meta({
			dbfield: dbfield, type_field: polyopts.type_field,
			types: polyopts.types, revert: revert_poly_belongs_to,
			clean_dirty: clean_poly_belongs_to
		});
	};

	var revert_poly_belongs_to = function revert_poly_belongs_to(meta) {
		if (this.get('_orig_' + meta.dbfield) !== undefined) {
			this.set('_' + meta.dbfield, this.get('_orig_' + meta.dbfield));
			this.set('_' + meta.type_field, this.get('_orig_' + meta.type_field));
			this.set('_orig_' + meta.dbfield, undefined);
			this.set('_orig_' + meta.type_field, undefined);
		}
		this.get_dirty_attrs().removeObject(meta.dbfield);
	};

	var clean_poly_belongs_to = function clean_poly_belongs_to(meta) {
		if (this.hasOwnProperty('_orig_' + meta.dbfield)) {
			this.set('_orig_' + meta.dbfield, undefined);
			this.set('_orig_' + meta.type_field, undefined);
		}
		this.get_dirty_attrs().removeObject(meta.dbfield);
	};

	var create_has_many = function create_has_many(dbfield, is_embedded, table, ckey, cval) {
		return _ember['default'].computed(function (key) {
			var meta = this.constructor.metaForProperty(key);
			var p = this.get('_' + meta.dbfield);
			if (p) return p;
			var a = undefined;
			if (is_embedded) a = EmbeddedManyArray.create({
				content: _ember['default'].A(), owner: this, child_table: meta.child_table,
				child_key: meta.ckey
			});else a = ManyArray.create({
				content: _ember['default'].A(), owner: this, child_table: meta.child_table,
				child_key: meta.ckey, child_field: meta.cval, dbfield: dbfield
			});
			this.set('_' + meta.dbfield, a);
			return a;
		}).meta({ child_table: table, dbfield: dbfield, ckey: ckey, cval: cval });
	};

	var adjust_ref_many = function adjust_ref_many(v) {
		var this_field_name = v.pname;
		var child_table = table(v.table);
		if (!child_table) return;

		var this_row_type = schema(this.get('row_type'));
		var this_pkey = this_row_type.proto().primary_key;

		var child_row_type = schema(child_table.get('row_type'));
		var child_pkey = child_row_type.proto().get('primary_key');
		var reduced_by_key = {};

		var key = '_' + child_row_type.metaForProperty(v.options.key).dbfield;

		child_table.forEach(function (r) {
			var child_row_key = r.get(key);
			if (!reduced_by_key[child_row_key]) reduced_by_key[child_row_key] = [];
			reduced_by_key[child_row_key].push(r.pk_value());
		});

		this.get('_content').forEach(function (this_row) {
			var val = reduced_by_key[this_row.pk_value()] || [];
			this_row.get(this_field_name).load(val);
		});
	};

	var adjust_embedded_many = function adjust_embedded_many(v) {
		var pname = v.pname;
		this.forEach(function (r) {
			r.notifyPropertyChange(pname);
		});
	};

	var set_attr = function set_attr(r, k, v) {
		if (k.charAt(0) != '_') {
			r.set(k, v);return;
		}
		if (r.get('_orig' + k) !== undefined) r.set('_orig' + k, v);else r.set(k, v);
	};

	var set_props = function set_props(r, ob) {
		r.beginPropertyChanges();
		_.each(ob, function (v, k, o) {
			var isattr = has_attr(r, k, 'isAttr') || has_attr(r, k, 'isBelong');
			var nk = isattr ? '_' + k : k;
			if (_.isArray(v)) {
				var opts = attr_opts(r, k);
				if (opts.isMany) nk = opts.pname;else if (!r.get(nk)) {
					r.set(nk, DataArray.create({
						content: _ember['default'].A(),
						owner: r, key: k,
						opts: attr_opts(r, k)
					}));
				}
				r.get(nk).load(v);
			} else if (_.isObject(v)) {
				if (!r.get(nk)) r.set(nk, _ember['default'].Object.create());
				set_props(r.get(nk), v);
			} else set_attr(r, nk, v);
		});
		r.endPropertyChanges();
	};

	var DataArray = _ember['default'].ArrayProxy.extend({
		isDirty: _ember['default'].computed('_orig_content', function () {
			return !_ember['default'].isNone(this.get('_orig_content'));
		}),

		init: function init() {
			this.set('isSaving', false);
			this.set('_orig_content', null);
			this.addArrayObserver(this);
			this._super();
		},

		arrayWillChange: function arrayWillChange(ob, start, rc, ac) {
			if (!this.get('_orig_content')) this.set('_orig_content', _.clone(this.get('content')));;
			this.set_dirty(true);
		},
		arrayDidChange: function arrayDidChange() {},

		set_dirty: function set_dirty(dirty) {
			if (dirty) this.get('owner._dirty_attrs').addObject(this.get('dbfield'));else this.get('owner._dirty_attrs').removeObject(this.get('dbfield'));
		},
		clean_dirty: function clean_dirty() {
			this.set('_orig_content', null);
			this.set_dirty(false);
		},
		load: function load(vflat) {
			var v = _.map(vflat, function (ob) {
				return _.isObject(ob) ? _ember['default'].Object.create(ob) : ob;
			});
			if (this.get('_orig_content') && !this.get('isSaving')) {
				this.set('_orig_content', v);
				return;
			}
			this.removeArrayObserver(this);
			var ct = this.get('content');
			this.beginPropertyChanges();

			/* calling .replace to avoid possibly overloaded objectAt call */
			_.each(_.difference(v, ct), function (e) {
				ct.replace(ct.length, 0, [e]);
			});
			for (var i = ct.length - 1; i >= 0; --i) {
				if (v.indexOf(ct[i]) == -1) ct.replace(i, 1);
			}this.endPropertyChanges();
			this.set('isSaving', false);
			this.set('_orig_content', null);
			this.addArrayObserver(this);
		},
		revert: function revert() {
			this.removeArrayObserver(this);
			this.set('content', this.get('_orig_content'));
			this.set('isSaving', false);
			this.set('_orig_content', null);
			this.addArrayObserver(this);
			this.set_dirty(false);
		},
		serialize: function serialize() {
			var sf = this.get('opts.options.serialize'),
			    c = this.get('content');
			return _.isFunction(sf) ? sf(c) : c;
		}
	});

	var ManyArray = DataArray.extend({
		set_dirty: function set_dirty(dirty) {
			if (dirty) this.get('owner').get_dirty_manys().addObject(this.get('dbfield'));else this.get('owner').get_dirty_manys().removeObject(this.get('dbfield'));
		},
		revert: function revert() {
			var _this = this;

			var adds = _.difference(this.get('content'), this.get('_orig_content'));
			var dels = _.difference(this.get('_orig_content'), this.get('content'));

			var filter = {};
			filter[this.get('child_key')] = this.get('owner');
			var child_table = table(this.get('child_table'));
			var child_field = this.get('child_field');

			_.each(adds, function (obj) {
				var ob = _this.objectAt(_this.get('content').indexOf(obj));
				filter[child_field] = ob;
				child_table.remove_where(filter);
			});

			_.each(dels, function (obj) {
				var ob = child_table.row_by_id(obj);
				if (_this.get('child_field')) ob = ob.get(_this.get('child_field'));
				filter[child_field] = ob;
				child_table.undelete(filter);
			});

			this._super.apply(this, arguments);
		},
		commit_many: function commit_many() {
			this.set('isSaving', true);
			/* called only for existing records */
			var adds = _.difference(this.get('content'), this.get('_orig_content'));
			var dels = _.difference(this.get('_orig_content'), this.get('content'));
			var child_table = table(this.get('child_table'));
			var rv = [];

			_.each(adds, function (obj) {
				var ob = child_table.row_by_id(obj);
				rv.push(ob.save());
			});

			_.each(dels, function (obj) {
				var ob = child_table.row_by_id(obj);
				rv.push(child_table.delete_commit(ob));
			});
			return _ember['default'].RSVP.allSettled(rv);
		},
		remove_all: function remove_all() {
			var filter = {};
			filter[this.get('child_key')] = this.get('owner');
			var child_table = table(this.get('child_table'));
			var child_field = this.get('child_field');
			this.forEach(function (obj) {
				filter[child_field] = obj;
				child_table.remove_where(filter);
			});
			this.clear();
		},
		commit_new_many: function commit_new_many() {
			var _this2 = this;

			/* called only for new model */
			var rv = [];
			var child_table = table(this.get('child_table'));
			this.beginPropertyChanges();
			this.get('content').forEach(function (chkey, idx) {
				var v = child_table.row_by_id(chkey);
				v.beginPropertyChanges();
				v.set(_this2.get('child_key'), null);
				v.set(_this2.get('child_key'), _this2.get('owner'));
				v.endPropertyChanges();
				child_table.row_pk_changed(chkey, v.pk_value());
				_this2.get('content')[idx] = v.pk_value();
				rv.push(v.save());
			});
			this.clear();
			this.endPropertyChanges();
			this.set('isSaving', true);
			return _ember['default'].RSVP.allSettled(rv);
		},
		objectAt: function objectAt(idx) {
			if (!(0 <= idx && idx < this.get('length'))) return undefined;
			var reftbl = this.get('child_table');
			var tbl = table(reftbl);
			if (!tbl) {
				/* TODO: in reality this is exception which means table is not defined */
				return undefined;
			}
			if (this.get('child_field')) {
				var ref_row = tbl.row_by_id(this.get('content').objectAt(idx));
				return ref_row ? ref_row.get(this.get('child_field')) : undefined;
			}
			return tbl.row_by_id(this.get('content').objectAt(idx)) || undefined;
		},
		addObject: function addObject(obj, link_props) {
			if (!_.isObject(obj)) return;
			var filter = {};
			filter[this.get('child_key')] = this.get('owner');
			filter[this.get('child_field')] = obj;
			_.extend(filter, link_props);
			var ckey = table(this.get('child_table')).find_or_create(filter);
			this.get('content').addObject(ckey);
			return this;
		},
		removeObject: function removeObject(obj) {
			if (!_.isObject(obj)) return;
			var filter = {};
			filter[this.get('child_key')] = this.get('owner');
			filter[this.get('child_field')] = obj;
			var ckey = table(this.get('child_table')).remove_where(filter);
			this.get('content').removeObject(ckey);
			return this;
		}
	});

	var EmbeddedManyArray = DataArray.extend({
		objectAt: function objectAt(idx) {
			if (!(0 <= idx && idx < this.get('length'))) return undefined;
			var reftbl = this.get('child_table');
			var tbl = table(reftbl);
			if (!tbl) {
				/* TODO: in reality this is exception which means table is not defined */
				return undefined;
			}
			return tbl.row_by_id(this.get('content').objectAt(idx)) || undefined;
		},
		addObject: function addObject(obj) {
			if (!_.isObject(obj)) return;
			var key = obj.get(obj.get('primary_key'));
			if (this.get('content').indexOf(key) != -1) return;
			this.get('content').addObject(key);
		},
		removeObject: function removeObject(obj) {
			if (!_.isObject(obj)) return;
			var key = obj.get(obj.get('primary_key'));
			if (this.get('content').indexOf(key) == -1) return;
			this.get('content').removeObject(key);
		},
		toggleObject: function toggleObject(obj) {
			if (!_.isObject(obj)) return;
			var key = obj.get(obj.get('primary_key'));
			var oidx = this.get('content').indexOf(key);
			this.get('content')[oidx == -1 ? 'addObject' : 'removeObject'](key);
		}
	});

	var Model = _ember['default'].Object.extend({
		primary_key: null,

		get_dirty_attrs: function get_dirty_attrs() {
			if (_.isUndefined(this.get('_dirty_attrs'))) this.set('_dirty_attrs', _ember['default'].A());
			return this.get('_dirty_attrs');
		},

		get_dirty_manys: function get_dirty_manys() {
			if (_.isUndefined(this.get('_dirty_manys'))) this.set('_dirty_manys', _ember['default'].A());
			return this.get('_dirty_manys');
		},

		isDirty: _ember['default'].computed('_dirty_attrs.[]', function () {
			return this.get_dirty_attrs().length > 0;
		}),
		isManyDirty: _ember['default'].computed('_dirty_manys.[]', function () {
			return this.get('_dirty_manys').length > 0;
		}),

		edit: function edit() {
			var _this3 = this;

			this.each_field(function (k, meta) {
				var prop = _this3.get(k);
				if (meta.set_dirty) meta.set_dirty.call(_this3, meta);else if (prop && _.isFunction(prop['set_dirty'])) prop.set_dirty(true);
			});
		},

		clean_all_dirty: function clean_all_dirty() {
			var _this4 = this;

			this.each_field(function (k, meta) {
				var prop = _this4.get(k);
				if (meta.clean_dirty) meta.clean_dirty.call(_this4, meta);else if (prop && _.isFunction(prop['clean_dirty'])) prop.clean_dirty();
			});
			this.set('_dirty_attrs', _ember['default'].A());
			this.set('_dirty_manys', _ember['default'].A());
		},
		pk_value: function pk_value() {
			var dbpk = pk2val(this, this.get('primary_key'));
			return dbpk || this.get('__guid__');
		},
		each_field: function each_field(iteratee) {
			var _this5 = this;

			var rtype = this.constructor.proto();
			_.each(rtype, function (v, k) {
				if (rtype[k] instanceof _ember['default'].ComputedProperty) {
					var meta = _this5.constructor.metaForProperty(k);
					iteratee(k, meta);
				}
			});
		},
		serialize: function serialize() {
			var _this6 = this;

			var rv = _.reduce(this.get('_attrs'), function (s, v, k) {
				if (v.isBelong) {
					var dbfield = v.options['aliases'];
					if (!dbfield) return s;
					s[dbfield] = _this6.get('_' + dbfield);
				} else if (v.isMany) {
					if (v.isEmbedded) s[k] = _this6.get('_' + k).serialize();
				} else if (v.isAttr) s[k] = _this6.get('_' + k);
				return s;
			}, {});
			return JSON.stringify(rv);
		},
		save: function save() {
			var _this7 = this;

			var self = this;
			if (!this.get('isDirty')) {
				if (!this.get('isManyDirty')) return null_promise();

				return new _ember['default'].RSVP.Promise(function (resolve, reject) {
					_this7.commit_many().then(resolve)['catch'](reject);
				});
			}

			var pk = pk2array(this, this.get('primary_key'));
			var row_json = this.serialize();
			var schema_name = this.get('schema_name');

			return new _ember['default'].RSVP.Promise(function (resolve, reject) {
				m_ajax_update(schema_name, pk, row_json).then(function () {
					self.commit_many().then(function () {
						self.clean_all_dirty();
						_ember['default'].run(null, resolve);
					})['catch'](reject);
				})['catch'](function (jqXHR) {
					_ember['default'].run(null, reject, jqXHR);
				});
			});
		},
		commit_many: function commit_many() {
			var _this8 = this;

			var rv = [];
			this.each_field(function (k, meta) {
				var prop = _this8.get(k);
				if (prop instanceof ManyArray) rv.push(prop.commit_many());
			});
			return _ember['default'].RSVP.allSettled(rv);
		},
		delete_commit_many: function delete_commit_many() {
			var _this9 = this;

			var rv = [];
			this.each_field(function (k) {
				var prop = _this9.get(k);
				if (prop instanceof ManyArray) {
					prop.remove_all();
					rv.push(prop.commit_many());
				}
			});
			return _ember['default'].RSVP.allSettled(rv);
		},

		revert: function revert() {
			var _this10 = this;

			var dangtb = this.get('__dangle_tbl__');
			if (dangtb) {
				return dangtb.delete_dangling(this);
			}
			if (!this.get('isDirty') && !this.get('isManyDirty')) return;
			this.each_field(function (k, meta) {
				var prop = _this10.get(k);
				var da = _this10.get_dirty_attrs().indexOf(meta.dbfield);
				var dm = _this10.get_dirty_manys().indexOf(meta.dbfield);
				if (da == -1 && dm == -1) return;
				if (meta.revert) meta.revert.call(_this10, meta);else if (prop && _.isFunction(prop['revert'])) prop.revert();
			});
			this.clean_all_dirty();
		}
	});

	exports.Model = Model;
	var NewModelProxy = _ember['default'].ObjectProxy.extend({
		_init_guid: _ember['default'].on('init', function () {
			this.set('__guid__', (0, _connectAppLibsGuidGen.v4)());
			this.get('content').edit();
		}),
		pk_value: function pk_value() {
			return this.get('content').pk_value();
		},
		commit_new_many: function commit_new_many(myrow) {
			var rv = [];
			myrow.each_field(function (k, meta) {
				var prop = myrow.get(k);
				if (prop instanceof ManyArray) rv.push(prop.commit_new_many());
			});
			return _ember['default'].RSVP.allSettled(rv);
		},
		save: function save() {
			var self = this;
			var row_json = self.get('content').serialize();
			var schema_name = this.get('schema_name');

			return new _ember['default'].RSVP.Promise(function (resolve, reject) {
				m_ajax_create(schema_name, row_json).then(function (result_json) {
					var myrow = self.get('content');
					var oldpk = self.pk_value();
					val2pk(myrow, myrow.get('primary_key'), result_json);
					var newpk = self.pk_value();
					var nc = self.get('parent_table').settle_new(oldpk, newpk, self);
					self.set('content', nc);
					var ret = self.commit_new_many(nc);
					ret.then(function () {
						nc.clean_all_dirty();
						_ember['default'].run(null, resolve);
					})['catch'](reject);
				})['catch'](function (jqXHR) {
					_ember['default'].run(null, reject, jqXHR);
				});
			});
		},
		revert: function revert() {
			this.get('parent_table').revert_new(this);
		},
		delete_commit_many: function delete_commit_many() {
			this.revert();
			return null_promise();
		}
	});

	Model.reopenClass({
		extend: function extend(decl) {
			if (this.proto()._attrs !== undefined) return this;
			var klass = _ember['default'].Object.extend.apply(this);
			var attrs = {};
			var db_schema = _ember['default'].A();

			_.each(decl, function (val, name) {
				if (val.isMany) {
					var dbfield = val.options['aliases'] || name;
					attrs[dbfield] = val;
					val.pname = name;
					var prop = {};
					prop[name] = create_has_many(dbfield, val.isEmbedded, val.table, val.options.key, val.options.val);
					klass.reopen(prop);
				} else if (val.isBelong) {
					var dbfield = val.options['aliases'];
					attrs[dbfield || name] = val;
					var prop = {};
					if (val.table) prop[name] = create_belongs_to(dbfield, val.table);else {
						var typefld = val.options.type_field;
						attrs[val.options.type_field] = { isAttr: true };
						var typeprop = {};
						typeprop[typefld] = create_attr(typefld, typefld);
						klass.reopen(typeprop);
						prop[name] = create_poly_belongs_to(dbfield, val.options);
					}
					klass.reopen(prop);
				} else if (val.isAttr) {
					var dbfield = val.options['aliases'] || name;
					attrs[dbfield] = val;
					var prop = {};prop[name] = create_attr(name, dbfield);
					klass.reopen(prop);
				} else {
					var prop = {};prop[name] = val;
					klass.reopen(prop);
				}
				if (val.options && _.size(val.options.display)) db_schema.addObject(val.options.display);
			});

			klass.reopen({ _attrs: attrs, db_schema: db_schema });
			return klass;
		}
	});

	var Table = _ember['default'].Object.extend(_ember['default'].Array, {
		adapter: _ember['default'].K,
		criteria: null,
		_fetching: false,
		_request_in_que: false,

		_num_deleted: 0,
		length: _ember['default'].computed('[]', '_num_deleted', function () {
			return this.get('_content').length - this.get('_num_deleted');
		}),
		'[]': _ember['default'].computed.alias('_content.[]'),

		_idhash: {},
		_ref_observers: false,
		_content: _ember['default'].A(),
		_new_records: _ember['default'].A(),

		_init_members: _ember['default'].on('init', function () {
			this.set('_idhash', _ember['default'].Object.create());
			this.set('_content', _ember['default'].A());
			this.set('_new_records', _ember['default'].A());
			this.set('_num_deleted', 0);

			this.set('adapter', this.get('adapter').create());
			this.set('adapter.table', this);
		}),

		setup_observers: function setup_observers() {
			var _this11 = this;

			if (this.get('_ref_observers')) return;
			var rklass = schema(this.get('row_type')).proto();
			_.each(rklass._attrs, function (v, k) {
				if (v.isMany) {
					var child_table = v.table;
					var ref_observer = v.isEmbedded ? adjust_embedded_many : adjust_ref_many;
					ref_observer = _.partial(ref_observer, v);
					table(child_table).addObserver('[]', _this11, ref_observer);
				} else if (v.isBelong) {
					/* we need internal reference to child tables for dependent property path */
					var child_tables = v.table ? [v.table] : v.options.types;
					var d = _.reduce(child_tables, function (c, v) {
						c['__' + v] = table(v);return c;
					}, {});
					rklass.reopen(d);
				}
			});
			this.set('_ref_observers', true);
		},

		create_new_record: function create_new_record(data) {
			var rtype = schema(this.get('row_type'));
			var r = rtype.create(data || {});
			var extr = this.get('_idhash')[r.pk_value()];
			if (extr) return extr;
			var rproxy = NewModelProxy.create({ content: r, parent_table: this });
			this.get('_idhash').set(r.pk_value(), rproxy);
			this.get('_new_records').addObject(rproxy);
			return rproxy;
		},

		settle_new: function settle_new(oldpk, newpk, new_row) {
			var row = new_row.get('content');
			var pkfilter = pk2filter(row, row.get('primary_key'));
			this.get('_new_records').removeObject(new_row);
			var settled = this._find_row(pkfilter, this.get('_content'));
			if (settled) {
				/* update from server for this row came before we got here */
				if (oldpk != newpk) delete this.get('_idhash')[oldpk];
				this.get('_idhash')[newpk] = settled;
				return settled;
			}
			if (oldpk != newpk) delete this.get('_idhash')[oldpk];
			this.get('_idhash')[newpk] = row;
			this.get('_content').insertAt(this.get('length'), row);
			return row;
		},

		revert_new: function revert_new(rw) {
			if (rw.get('__dangle_new__')) {
				rw.get('content').revert();
				return this.settle_new(rw.pk_value(), rw.pk_value(), rw);
			}
			this.get('_new_records').removeObject(rw);
			delete this.get('_idhash')[rw.pk_value()];
			if (this.get('_idhash')[rw.pk_value()] !== rw) delete this.get('_idhash')[rw.pk_value()];
		},

		save_all: function save_all() {
			var rv = this.get('_content').map(function (r) {
				return r.save();
			});
			return _ember['default'].RSVP.allSettled(rv);
		},

		delete_commit: function delete_commit(row) {
			var rv = [];
			rv.push(row.delete_commit_many());
			var del_pk = this.remove_where(row);
			var val_pk = pk2array(row, row.get('primary_key')).reduce(function (l, r) {
				return l && !!r;
			}, true);
			row.clean_all_dirty();
			if (del_pk && val_pk) rv.push(m_ajax_delete(row.get('schema_name'), pk2array(row, row.get('primary_key'))));
			return _ember['default'].RSVP.allSettled(rv);
		},

		delete_dangling: function delete_dangling(row) {
			this.get('_content').removeObject(row);
			delete this.get('_idhash')[row.pk_value()];
		},

		reload: function reload(arr) {
			this.setup_observers();
			this.sync_table(arr);
		},

		sync_table: function sync_table(row_array) {
			var _this12 = this;

			if (!_.isArray(row_array) || !this.get('row_type')) return;
			var rtype = schema(this.get('row_type'));
			var attrs = rtype.proto()._attrs;
			var dbfields = _.map(attrs, function (v, k) {
				if (rtype.proto().hasOwnProperty(k)) return rtype.metaForProperty(k).dbfield;
				return k;
			});

			/* change primary key fields to aliased (db) versions */
			var pkdb = rtype.proto().primary_key;
			if (!_.isArray(pkdb)) pkdb = [pkdb];
			pkdb = _.map(pkdb, function (v) {
				if (rtype.proto().hasOwnProperty(v)) return rtype.metaForProperty(v).dbfield;
				return v;
			});
			if (pkdb.length == 1) pkdb = pkdb[0];

			var ct = this.get('_content');
			ct.beginPropertyChanges();

			// first time table is filled, collect all rows in plain js array
			// and create ember array in the end (cca 3x perf improvement)
			if (ct.length === 0) {
				(function () {
					var _ct = [];
					_.each(row_array, function (ob) {
						if (ob['opcode'] === 0) return;
						var filtered_ob = _.pick(ob, dbfields);
						var id = pk2val(filtered_ob, pkdb).toString();
						var r = rtype.create();
						_this12.get('_idhash').set(id, r);
						_ct.push(r);
						set_props(r, filtered_ob);
					});
					_this12.set('_content', _ember['default'].A(_ct));
				})();
			} else {
				_.each(row_array, function (ob) {
					var filtered_ob = _.pick(ob, dbfields);
					var id = pk2val(filtered_ob, pkdb).toString();
					var r = _this12.get('_idhash').get(id);
					if (ob['opcode'] === 0) {
						if (r) {
							if (!r.get('isDirty')) {
								delete _this12.get('_idhash')[id];
								ct.removeObject(r);
							} else r.set('__dangle_tbl__', _this12);
						}
					} else {
						if (!r) {
							r = rtype.create();
							_this12.get('_idhash').set(id, r);
							ct.addObject(r);
						} else if (r instanceof NewModelProxy) r.set('__dangle_new__', _this12);
						set_props(r, filtered_ob);
					}
				});
			}
			if (_.findWhere(_.values(attrs), { isMany: true, isEmbedded: false })) {
				_.each(attrs, function (v, pname) {
					if (!v.isMany || v.isEmbedded) return;
					adjust_ref_many.call(_this12, v, pname);
				});
			}
			this.set('_num_deleted', 0);
			ct.endPropertyChanges();
		},
		row_by_id: function row_by_id(id) {
			return this.get('_idhash').get(id.toString());
		},
		row_pk_changed: function row_pk_changed(oldkey, newkey) {
			var row = this.get('_idhash')[oldkey];
			this.get('_idhash').set(newkey, row);
			delete this.get('_idhash')[oldkey];
		},
		objectAt: function objectAt(index) {
			return this.get('_content').objectAt(index);
		},

		_find_row: function _find_row(filter, arr) {
			if (filter instanceof Model || filter instanceof NewModelProxy) return arr.indexOf(filter) != -1 ? filter : undefined;
			return arr.find(function (row) {
				return _.every(filter, function (v, k) {
					return row.get(k) == v;
				});
			});
		},
		find_or_create: function find_or_create(filter) {
			var rw = this._find_row(filter, this.get('_content'));
			if (rw) return rw.pk_value();
			rw = this._find_row(filter, this.get('_new_records')) || this.create_new_record(filter);
			return rw.pk_value();
		},
		remove_where: function remove_where(filter) {
			var ct = this.get('_content');
			var row = this._find_row(filter, ct);
			if (row) {
				var _ct2 = this.get('_content');
				var len = this.get('length');
				if (len > 0) {
					var row_idx = _ct2.indexOf(row);
					var tmp = _ct2[len - 1];
					_ct2[len - 1] = row;
					_ct2[row_idx] = tmp;
				}
				this.incrementProperty('_num_deleted');
				return row.pk_value();
			}
			row = this._find_row(filter, this.get('_new_records'));
			if (row) {
				var guid = row.pk_value();
				this.get('_new_records').removeObject(row);
				this.get('_idhash').set(guid, undefined); /* to notify observers */
				delete this.get('_idhash')[guid];
				return guid;
			}
			return undefined;
		},
		undelete: function undelete(filter) {
			var ct = this.get('_content');
			var row = this._find_row(filter, ct);
			if (!row) return;

			if (this.get('_num_deleted')) {
				var len = this.get('length');
				var row_idx = ct.indexOf(row);
				var tmp = ct[len];
				ct[len] = row;
				ct[row_idx] = tmp;
			}
			this.decrementProperty('_num_deleted');
		},
		clear: function clear() {
			this.get('_content').clear();
			this.set('_idhash', _ember['default'].Object.create());
		}
	});

	exports.Table = Table;

	function attr(opts) {
		return { isAttr: true, options: opts || {} };
	}

	function belongs_to(table, opts) {
		return { isBelong: true, table: table, options: opts || {} };
	}

	function has_many(table, opts) {
		return { isMany: true, isAttr: true, isEmbedded: !!opts.embedded, table: table, options: opts || {} };
	}

	var tbl_inst = {};

	function table(table) {
		if (tbl_inst[table]) return tbl_inst[table];
		var tklass = Tables[table];
		if (!tklass) return undefined;
		tbl_inst[table] = tklass.create();
		return tbl_inst[table];
	}

	function schema(schema_name) {
		var rv = Schemas[schema_name];
		if (!rv) return undefined;
		if (rv.proto().hasOwnProperty('schema_name') == false) rv.proto().reopen({ schema_name: schema_name });
		return rv;
	}
});