define('connect-app/components/user-menu', ['exports', 'ember', 'connect-app/libs/auth', 'connect-app/initializers/model-loader', 'connect-app/libs/utils'], function (exports, _ember, _connectAppLibsAuth, _connectAppInitializersModelLoader, _connectAppLibsUtils) {
	exports['default'] = _ember['default'].Component.extend({
		name: '',

		partner_name: _ember['default'].computed('DB.userinfo.[]', function () {
			return (0, _connectAppLibsUtils.model_field_by_id)('UserInfo', 'partner_name', _connectAppLibsAuth['default'].get('user.user_id'));
		}),

		_init: _ember['default'].on('init', function () {
			_connectAppInitializersModelLoader.DB.boot();
			this.set('name', _connectAppLibsAuth['default'].get('user.name') || _connectAppLibsAuth['default'].get('user.preferred_username'));
		}),

		actions: {
			logout: function logout() {
				_connectAppLibsAuth['default'].logout();
			}
		}
	});
});