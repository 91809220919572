define('connect-app/components/device-input-textbox', ['exports', 'ember', 'connect-app/libs/auth', 'connect-app/libs/utils'], function (exports, _ember, _connectAppLibsAuth, _connectAppLibsUtils) {

	var MAX_BATCH_SIZE = 20000;

	exports['default'] = _ember['default'].Component.extend({
		max_devices: null,
		validator: null,
		submit_url: null,
		submit_action: null,

		input: '',
		input_scroll_position: 0,
		submit_in_progress: false,
		partner_id: (0, _connectAppLibsUtils.get_partner_id)(),
		_elem_bag: [],

		device_ids: _ember['default'].computed('input', function () {
			return _.uniq(this.get('input').split(/\r?\n/).filter(function (x) {
				return x;
			}));
		}),

		exceeded_max_batch_size: _ember['default'].computed.gt('device_ids.length', MAX_BATCH_SIZE),

		over_licence_limit: _ember['default'].computed('max_devices', 'device_ids', function () {
			return this.get('device_ids.length') > this.get('max_devices');
		}),
		placeholder: _ember['default'].computed('partner_id', function () {
			return this.get('partner_id') === 17 ? 'device_input_textbox.placeholder_textarea_vin' : 'device_input_textbox.placeholder_textarea';
		}),
		invalid_input_lines: _ember['default'].computed('input', function () {
			var validator = this.get('validator');
			var invalid_lines = [];
			var line_num = 0;

			var _iteratorNormalCompletion = true;
			var _didIteratorError = false;
			var _iteratorError = undefined;

			try {
				for (var _iterator = this.get('input').split(/\r?\n/).map(function (x) {
					return x.trim();
				})[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
					var line = _step.value;

					if (line.length > 0 && !validator(line)) invalid_lines.push(line_num);
					++line_num;
				}
			} catch (err) {
				_didIteratorError = true;
				_iteratorError = err;
			} finally {
				try {
					if (!_iteratorNormalCompletion && _iterator['return']) {
						_iterator['return']();
					}
				} finally {
					if (_didIteratorError) {
						throw _iteratorError;
					}
				}
			}

			return invalid_lines;
		}),

		draw_invalid_lines: _ember['default'].observer('input', 'invalid_input_lines', 'input_scroll_position', function () {
			var lines = this.get('invalid_input_lines');
			var elemBag = this.get('_elem_bag');

			var offset_top = 8 - this.get('input_scroll_position');
			var lineHeight = 20;

			this.updateElemBag(lines.length);

			for (var i = 0; i < lines.length; ++i) {
				var elem = elemBag[i];
				elem.id = 'error-line-' + lines[i];
				elem.style.top = lines[i] * lineHeight + offset_top + 'px';
				elem.style.display = 'block';
			}
		}),

		inputs_ok: _ember['default'].computed('device_ids.[]', 'invalid_input_lines.[]', 'exceeded_max_batch_size', function () {
			return this.get('device_ids.length') > 0 && !this.get('exceeded_max_batch_size') && !this.get('over_licence_limit') && this.get('invalid_input_lines.length') == 0;
		}),

		didRender: function didRender() {
			var _this = this;

			this._super.apply(this, arguments);
			document.getElementById("txtarea-input").onscroll = function (evt) {
				return _this.set('input_scroll_position', evt.target.scrollTop);
			};
		},

		updateElemBag: function updateElemBag(count) {
			var elemBag = this.get('_elem_bag');
			var newSize = elemBag.length;
			var draw_board = document.getElementById('txtarea-input-shadow');

			if (elemBag.length < count) newSize = Math.max(8, 1 << Math.floor(Math.log2(count) + 1));else if (elemBag.length >= 2 * count) newSize = Math.max(8, Math.floor(elemBag.length / 2));

			while (elemBag.length > newSize) draw_board.removeChild(elemBag.pop());

			while (elemBag.length < newSize) {
				var elem = document.createElement('div');
				draw_board.appendChild(elem);
				elemBag.push(elem);
			}

			var _iteratorNormalCompletion2 = true;
			var _didIteratorError2 = false;
			var _iteratorError2 = undefined;

			try {
				for (var _iterator2 = elemBag[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
					var elem = _step2.value;

					elem.className = 'input-textarea--shadow__error-line';
					elem.style.display = 'none';
				}
			} catch (err) {
				_didIteratorError2 = true;
				_iteratorError2 = err;
			} finally {
				try {
					if (!_iteratorNormalCompletion2 && _iterator2['return']) {
						_iterator2['return']();
					}
				} finally {
					if (_didIteratorError2) {
						throw _iteratorError2;
					}
				}
			}

			this.set('_elem_bag', elemBag);
		},

		canReadFile: _ember['default'].computed(function () {
			return window.File && window.FileReader && window.FileList && window.Blob;
		}),

		actions: {
			activate_file_select: function activate_file_select() {
				document.getElementById('file_select').click();
			},

			file_selected: function file_selected(evt) {
				var _this2 = this;

				var file = evt.target.files[0];
				if (_.isUndefined(file)) return;

				evt.target.value = "";

				var reader = new FileReader();
				reader.onload = function (evt) {
					var input = _this2.get('input');
					_this2.set('input', '' + input + (!input || input.endsWith('\n') ? '' : '\n') + evt.target.result.split(/\r?\n/).filter(function (x) {
						return x;
					}).join('\n'));
				};
				reader.onabort = function (evt) {
					return console.error('file reading error', file);
				};
				reader.readAsText(file);
			},

			submit: function submit() {
				var _this3 = this;

				if (this.get('submit_in_progress') || !this.get('inputs_ok')) return;

				this.set('submit_in_progress', true);

				_connectAppLibsAuth['default'].refresh_token().then(function () {
					$.ajax({
						type: 'POST',
						url: _this3.get('submit_url'),
						data: $.param({ device_ids: _this3.get('device_ids') }, true),
						processData: false,
						headers: {
							Authorization: 'Bearer ' + _connectAppLibsAuth['default'].get('token')
						},
						success: function success(msg) {
							if (msg.status == 1) {
								_this3.set('input', '');
								_ember['default'].run.later(_this3, _this3.get('submit_action'), 250);
							} else alert('Error: Couldn\'t generate licences.');
						},
						error: function error(jqXHR, textStatus, errorThrown) {
							return console.error(textStatus, errorThrown);
						},
						complete: function complete(xhr, status) {
							return _this3.set('submit_in_progress', false);
						}
					});
				})['catch'](function (err) {
					return _this3.set('submit_in_progress', false);
				});
			}
		}
	});
});