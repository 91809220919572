define('connect-app/transitions', ['exports'], function (exports) {
	var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

	function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

	var DURATION = 500;
	var ANIMATIONS = {
		'swipe-left': {
			front: ['toLeft', { DURATION: DURATION }],
			back: ['toRight', { DURATION: DURATION }]
		},
		'swipe-right': {
			front: ['toRight', { DURATION: DURATION }],
			back: ['toLeft', { DURATION: DURATION }]
		},
		'swap': {
			front: ['crossFade', { DURATION: DURATION }],
			back: ['crossFade', { DURATION: DURATION }]
		}
	};

	var transitions = ['projects -> project-detail :: swipe-left', 'projects -> ac-project-detail :: swipe-left', 'projects -> pa-project-detail :: swipe-left', 'project-detail -> licence-files :: swipe-left', 'project-detail -> licence-new :: swipe-left', 'licence-files -> licence-new :: swap', 'licence-new -> projects :: swipe-right', 'licence-files -> projects :: swipe-right', 'ac-project-detail -> ac-codes-generate :: swipe-left', 'ac-project-detail -> ac-batches-list :: swipe-left', 'ac-batches-list -> ac-codes-generate :: swap', 'ac-codes-generate -> projects :: swipe-right', 'ac-batches-list -> projects :: swipe-right', 'ac-batches-list -> ac-batch-show :: swipe-left', 'ac-batch-show -> ac-project-detail :: swipe-right', 'ac-batch-show -> projects :: swipe-right', 'pa-project-detail -> pa-batch-new :: swipe-left', 'pa-project-detail -> pa-batches-list :: swipe-left', 'pa-batches-list -> pa-batch-new :: swap', 'pa-batch-new -> projects :: swipe-right', 'pa-batches-list -> projects :: swipe-right'];

	exports['default'] = function () {
		var _iteratorNormalCompletion = true;
		var _didIteratorError = false;
		var _iteratorError = undefined;

		try {
			for (var _iterator = transitions[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
				var transition = _step.value;

				var _transition$split$map = transition.split('::').map(function (x) {
					return x.trim();
				});

				var _transition$split$map2 = _slicedToArray(_transition$split$map, 2);

				var move = _transition$split$map2[0];
				var anname = _transition$split$map2[1];

				var _move$split$map = move.split('->').map(function (x) {
					return x.trim();
				});

				var _move$split$map2 = _slicedToArray(_move$split$map, 2);

				var src = _move$split$map2[0];
				var dst = _move$split$map2[1];

				var animation = ANIMATIONS[anname];
				this.transition(this.fromRoute(src), this.toRoute(dst), this.use.apply(this, _toConsumableArray(animation.front)), this.reverse.apply(this, _toConsumableArray(animation.back)));
			}
		} catch (err) {
			_didIteratorError = true;
			_iteratorError = err;
		} finally {
			try {
				if (!_iteratorNormalCompletion && _iterator['return']) {
					_iterator['return']();
				}
			} finally {
				if (_didIteratorError) {
					throw _iteratorError;
				}
			}
		}
	};

	;
});