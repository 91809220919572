define('connect-app/components/pa-project-detail', ['exports', 'ember', 'connect-app/initializers/model-loader', 'connect-app/libs/utils'], function (exports, _ember, _connectAppInitializersModelLoader, _connectAppLibsUtils) {
    exports['default'] = _ember['default'].Component.extend({
        project_id: null,

        didReceiveAttrs: function didReceiveAttrs() {
            this._super.apply(this, arguments);
            _connectAppInitializersModelLoader.DB.set('paproducts.criteria', { "project_id": this.get('project_id') });
        },

        project_data: (0, _connectAppLibsUtils.computed_model)('PAProjects', 'project_id'),

        total_cost: _ember['default'].computed('project_data', function () {
            return this.get('project_data.licenced_devices') * this.get('project_data.price');
        }),

        content: _ember['default'].computed('DB.paproducts.[]', 'DB.productcategories.[]', function () {
            if (_connectAppInitializersModelLoader.DB.productcategories.length == 0) return [];

            var content = [];
            _connectAppInitializersModelLoader.DB.paproducts.forEach(function (product) {
                var category_id = product.get('category.category_id');
                var category_name = product.get('category.category_name');

                if (_.isUndefined(content[category_id])) content[category_id] = { name: category_name, items: [] };

                content[category_id].items.pushObject(product.get('ref_name'));
            });

            return content;
        })
    });
});