define('connect-app/components/icon-list-item', ['exports', 'ember'], function (exports, _ember) {

	var icon_map = {
		'i_save': '',
		'i_unlocked': '',
		'i_doc_full': '',
		'i_tablet': '',
		'nav_car': '',
		'map_pin': '',
		's_check3': '',
		'null': ''
	};

	exports['default'] = _ember['default'].Component.extend({
		item: null,
		label: null,
		extras: null,
		icon: null,
		colorful: true,

		icon_image: _ember['default'].computed('icon', function () {
			return icon_map[this.get('icon')];
		}),

		whole_clickable: _ember['default'].computed('icon', function () {
			return this.get('icon') != 'i_save';
		}),

		label_text: _ember['default'].computed('label', function () {
			var label = this.get('label');
			return _.isArray(label) ? label.join(' • ') : label;
		}),

		extras_text: _ember['default'].computed('extras', function () {
			var extras = this.get('extras');
			return _.isArray(extras) ? extras.join(' • ') : extras;
		}),

		actions: {
			item_click: function item_click() {
				var action = this.get('onItemClicked');
				if (!!action) action();
			}
		}
	});
});