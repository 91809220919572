define('connect-app/components/projects-list', ['exports', 'ember', 'connect-app/initializers/model-loader', 'connect-app/libs/utils'], function (exports, _ember, _connectAppInitializersModelLoader, _connectAppLibsUtils) {

	var DETAILS = { 1: 'project-detail', 2: 'ac-project-detail', 3: 'pa-project-detail' };

	exports['default'] = _ember['default'].Component.extend({
		service_projects_filter: _ember['default'].inject.service('projects-filter'),

		didReceiveAttrs: function didReceiveAttrs() {
			this._super.apply(this, arguments);
			this.set('service_projects_filter.search_terms', '');
		},

		is_user_admin: (0, _connectAppLibsUtils.is_admin)(),
		partner_id: (0, _connectAppLibsUtils.get_partner_id)(),

		projects: _ember['default'].computed('service_projects_filter.search_tokens', 'DB.projects.[]', 'DB.acprojects.[]', 'DB.paprojects.[]', 'is_user_admin', function () {
			var _this = this;

			var all_projects = [].concat(_connectAppInitializersModelLoader.DB.projects.toArray(), _connectAppInitializersModelLoader.DB.acprojects.toArray(), _connectAppInitializersModelLoader.DB.paprojects.toArray());
			var projects_search = this.get('service_projects_filter');
			var is_admin = this.get('is_user_admin');

			var visible_projects = all_projects.filter(function (x) {
				return projects_search.is_match(x, x.get('project_type'));
			});
			visible_projects.forEach(function (project) {
				if (project.get('project_type') == 1) project.setProperties({
					'text_label': project.get('name'),
					'extras': project.get('app_series') + (is_admin ? ' • ' + project.get('partner_name') : ''),
					'icon': _this.get("partner_id") === 17 ? 'map_pin' : 'nav_car'
				});else if (project.get('project_type') == 2) project.setProperties({
					'text_label': project.get('name'),
					'extras': 'Activation codes' + (is_admin ? ' • ' + project.get('partner_name') : ''),
					'icon': 'i_unlocked'
				});else if (project.get('project_type') == 3) project.setProperties({
					'text_label': project.get('name'),
					'extras': [project.get('app_series'), 'Preactivated products' + (is_admin ? ' • ' + project.get('partner_name') : '')],
					'icon': 's_check3'
				});
			});
			return _.sortBy(visible_projects, function (project) {
				return project.get('text_label').toLowerCase();
			});
		}),

		actions: {
			project_selected: function project_selected(project) {
				this.get('router').transitionTo(DETAILS[project.get('project_type')], { project_id: project.get('project_id') });
			}
		}

	});
});