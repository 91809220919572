define('connect-app/components/licence-new', ['exports', 'ember', 'connect-app/libs/auth', 'connect-app/initializers/model-loader', 'connect-app/libs/utils'], function (exports, _ember, _connectAppLibsAuth, _connectAppInitializersModelLoader, _connectAppLibsUtils) {
	exports['default'] = _ember['default'].Component.extend({
		project_id: null,

		UNLIMITED_LICENCES: Number.MAX_SAFE_INTEGER,

		new_licence: false,

		project: (0, _connectAppLibsUtils.computed_model)('Projects', 'project_id'),

		max_licences: _ember['default'].computed('project', function () {
			var max_lics = this.get('project.max_licences');
			return max_lics < 0 ? Number.MAX_SAFE_INTEGER : max_lics - this.get('project.licenced_devices');
		}),

		validator_method: _ember['default'].computed('project.ctag', function () {
			var ctag = this.get('project.ctag');

			if (!(ctag in _connectAppLibsUtils.validators)) ctag = "sdcard_id_plain";

			return _connectAppLibsUtils.validators[ctag];
		}),

		submit_url: _ember['default'].computed('project_id', 'new_licence', function () {
			return _connectAppLibsAuth['default'].get('app_origin') + '/AddDevices?project_id=' + this.get('project_id') + '&new_licence=' + (this.get('new_licence') ? 1 : 0);
		}),

		create_licence: _ember['default'].computed('project_id', function () {
			var _this = this;

			return function () {
				_connectAppInitializersModelLoader.DB.licences.clear();
				_this.get('router').transitionTo('licence-files', { project_id: _this.get('project_id') });
			};
		})
	});
});