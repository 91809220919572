define('connect-app/libs/auth', ['exports', 'ember', 'connect-app/libs/keycloak', 'connect-app/config/environment'], function (exports, _ember, _connectAppLibsKeycloak, _connectAppConfigEnvironment) {

	var auth = _ember['default'].Object.extend({
		user: { 'user_id': 0, 'access-from': '' },
		is_logged: _ember['default'].computed.gt('user.user_id', 0),
		token: null,

		init: function init() {
			var keycloak = new _connectAppLibsKeycloak['default']({
				url: _connectAppConfigEnvironment['default'].APP.auth_uri,
				realm: _connectAppConfigEnvironment['default'].APP.auth_realm,
				clientId: _connectAppConfigEnvironment['default'].APP.auth_client_id
			});

			var kcLogin = keycloak.createLoginUrl;
			keycloak.createLoginUrl = function (opts) {
				if (document.referrer === _connectAppConfigEnvironment['default'].APP.modis_referrer) opts.idpHint = _connectAppConfigEnvironment['default'].APP.idp_hint;
				return kcLogin(opts);
			};

			this.set('_keycloak', keycloak);
		},

		user_info: function user_info() {
			var _this = this;

			return new _ember['default'].RSVP.Promise(function (resolve, reject) {
				var keycloak = _this.get('_keycloak');
				keycloak.init({ onLoad: 'login-required', checkLoginIframe: false }).success(function () {
					keycloak.loadUserInfo().success(function (userInfo) {
						userInfo.user_id = userInfo.mireo_id;
						_this.set('user', userInfo);
						_this.set('token', keycloak.token);
						resolve();
					});
				});
			});
		},

		logout: function logout() {
			this.set('user', { user_id: 0 });
			this.get('_keycloak').logout();
		},

		refresh_token: function refresh_token() {
			var _this2 = this;

			return new _ember['default'].RSVP.Promise(function (resolve, reject) {
				_this2.get('_keycloak').updateToken(5).success(function (refreshed) {
					_this2.set('token', _this2.get('_keycloak').token);
					resolve();
				}).error(function () {
					_this2.set('token', null);
					reject();
				});
			});
		}
	});

	exports['default'] = auth.create();
});