define('connect-app/initializers/model-loader', ['exports', 'ember', 'connect-app/libs/mireo-data', 'connect-app/models/schema'], function (exports, _ember, _connectAppLibsMireoData, _connectAppModelsSchema) {

    // A data store used to keep all defined models and preload them for later use
    var DB_factory = _ember['default'].Object.extend({
        boot: function boot() {
            this._super();
            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
                for (var _iterator = Object.keys(_connectAppLibsMireoData['default'].Tables)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                    var model = _step.value;

                    this[model.toLowerCase()] = _connectAppLibsMireoData['default'].table(model);
                }
            } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion && _iterator['return']) {
                        _iterator['return']();
                    }
                } finally {
                    if (_didIteratorError) {
                        throw _iteratorError;
                    }
                }
            }
        }
    });

    var DB = DB_factory.create();

    exports.DB = DB;
    exports['default'] = {
        name: 'arthur-db-load',
        after: 'login',
        initialize: function initialize(application) {
            application.register('db:main', DB, { instantiate: false });
            application.inject('component', 'DB', 'db:main');
        }
    };
});