define('connect-app/components/ac-batch-show', ['exports', 'ember', 'connect-app/initializers/model-loader', 'connect-app/libs/utils'], function (exports, _ember, _connectAppInitializersModelLoader, _connectAppLibsUtils) {
    exports['default'] = _ember['default'].Component.extend({
        project_id: null,
        batch_id: null,

        didReceiveAttrs: function didReceiveAttrs() {
            this._super.apply(this, arguments);
            _connectAppInitializersModelLoader.DB.set('acbatches.criteria', { project_id: this.get('project_id'), code_prefix: '' });
            _connectAppInitializersModelLoader.DB.set('accodes.criteria', { project_id: this.get('project_id'), batch_id: this.get('batch_id') });
        },

        project: (0, _connectAppLibsUtils.computed_model)('ACProjects', 'project_id'),
        batch: (0, _connectAppLibsUtils.computed_model)('ACBatches', 'batch_id'),
        codes: _ember['default'].computed.mapBy('DB.accodes.[]', 'activation_code'),

        actions: {
            download_csv: function download_csv() {
                var ext_prod_id = this.get('project.ext_prod_id');
                var data = 'data:text/csv;charset=utf-8,' + encodeURI('"Activation codes","Links"\r\n' + this.get('codes').map(function (code) {
                    return code + ', http://geniusmaps.com/acodes.html?c=' + code + '&p=' + ext_prod_id;
                }).join('\r\n'));
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.setAttribute('href', data);
                link.setAttribute('download', 'batch_' + this.get('batch_id') + '.csv');
                link.click();
            }
        }
    });
});