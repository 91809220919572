define('connect-app/libs/utils', ['exports', 'connect-app/libs/auth', 'connect-app/locales/en/translations', 'connect-app/initializers/model-loader'], function (exports, _connectAppLibsAuth, _connectAppLocalesEnTranslations, _connectAppInitializersModelLoader) {
	exports.computed_model = computed_model;
	exports.datetime_format = datetime_format;
	exports.human_file_size = human_file_size;
	exports.human_readable_date = human_readable_date;
	exports.is_admin = is_admin;
	exports.get_partner_id = get_partner_id;
	exports.local_datetime_format = local_datetime_format;
	exports.model_by_id = model_by_id;
	exports.model_field_by_id = model_field_by_id;
	exports.translate = translate;
	exports.download_binary = download_binary;

	function computed_model(model, id_field) {
		model = model.toLowerCase();

		return Ember.computed('DB.' + model + '.[],' + id_field, function () {
			var id = this.get(id_field);
			return !_.isUndefined(id) ? _connectAppInitializersModelLoader.DB[model].row_by_id(id) : undefined;
		});
	}

	function datetime_format(date_str) {
		function d2(n) {
			return (n < 10 ? '0' : '') + n.toString();
		}

		var d = new Date(date_str * 1000);
		return '' + d.getYear() % 100 + d2(d.getMonth() + 1) + d2(d.getDate()) + '.' + d2(d.getHours()) + d2(d.getMinutes()) + d2(d.getSeconds());
	}

	function human_file_size(bytes) {
		var sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
		if (bytes == 0) return '0 B';
		var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
		return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
	}

	function human_readable_date(timestamp) {
		var date = new Date(timestamp * 1000);
		var today = new Date().setHours(0, 0, 0, 0);
		var yesterday = today - 24 * 60 * 60 * 1000;
		if (date > today) return 'Today';else if (date > yesterday) return 'Yesterday';else return date.toLocaleDateString();
	}

	function is_admin() {
		return Ember.computed('DB.userinfo.[]', function () {
			var browsing_user = _connectAppInitializersModelLoader.DB.userinfo.row_by_id(_connectAppLibsAuth['default'].get('user.user_id'));
			return !_.isUndefined(browsing_user) && browsing_user.get('partner_id') === 1;
		});
	}

	function get_partner_id() {
		return Ember.computed('DB.userinfo.[]', function () {
			var browsing_user = _connectAppInitializersModelLoader.DB.userinfo.row_by_id(_connectAppLibsAuth['default'].get('user.user_id'));
			return _.isUndefined(browsing_user) ? null : browsing_user.get('partner_id');
		});
	}

	function local_datetime_format(date_str) {
		var d = new Date(date_str * 1000);
		return d.toLocaleString();
	}

	function model_by_id(model, id) {
		model = model.toLowerCase();
		return !_.isUndefined(id) ? _connectAppInitializersModelLoader.DB[model].row_by_id(id) : undefined;
	}

	function model_field_by_id(model, field, id) {
		var row = model_by_id(model, id);
		return !_.isUndefined(row) ? row.get(field) : undefined;
	}

	function translate(item) {

		function deep_value(obj, path) {
			for (var i = 0, path = path.split('.'), len = path.length; i < len; i++) obj = obj[path[i]];
			return obj;
		}

		return deep_value(_connectAppLocalesEnTranslations['default'], item);
	}

	var validators = {
		device_id_plain: function device_id_plain(device) {
			return device && device.length >= 8;
		},

		device_id_cvte: function device_id_cvte(device) {
			return !!device.match(/^([0-9A-Fa-f]{4}-){7}[0-9A-Fa-f]{4}$/);
		},

		factory_imei: function factory_imei(device) {
			return !!device.match(/^.{15}$/);
		},

		imei: function imei(device) {
			return !!device.match(/^.{15}$/);
		},

		ios_device_id: function ios_device_id(device) {
			return !!device.match(/^[a-z0-9]{40}$/);
		},

		mac: function mac(device) {
			return !!device.match(/^[0-9A-Fa-f]{12}$/);
		},

		sdcard_id_plain: function sdcard_id_plain(device) {
			return !!device.match(/^[0-9A-Fa-f]{8}$/);
		}
	};

	exports.validators = validators;

	function download_binary(url) {
		var _this = this;

		var settings = {
			url: _connectAppLibsAuth['default'].get('app_origin') + '/' + url,
			crossDomain: true,
			headers: {
				Authorization: 'Bearer ' + _connectAppLibsAuth['default'].get('token')
			},
			xhrFields: {
				responseType: 'blob'
			}
		};

		return _connectAppLibsAuth['default'].refresh_token().then(function () {
			return new Ember.RSVP.Promise(function (resolve, reject) {
				settings.success = function (res, status, xhr) {

					var header = xhr.getResponseHeader('Content-Disposition');
					var name = header.replace(/"/gm, '').split('filename=')[1].split(';')[0];
					console.log(header, name, res);

					var objectUrl = window.URL.createObjectURL(res);
					var anchor = document.createElement('a');
					anchor.href = objectUrl;
					anchor.download = name;
					anchor.click();
					window.URL.revokeObjectURL(objectUrl);
					Ember.run.next(_this, function () {
						anchor.remove();
					});

					Ember.run(null, resolve, res);
				};

				settings.error = function (jqXHR, textStatus, errorThrown) {
					console.log('jqXHR error ', textStatus);
					if (jqXHR && typeof jqXHR === 'object') {
						jqXHR.then = null;
						if (jqXHR.status == 502) {
							Ember.run.join(null, reject, 'ERR_BAD_GATEWAY');
							return;
						}
					}
					if (reject) Ember.run(null, reject, jqXHR);
				};

				Ember.$.ajax(settings);
			});
		})['catch'](function (err) {
			console.log('fail refresh_token for download');
		});
	}
});