define('connect-app/locales/en/translations', ['exports'], function (exports) {
    exports['default'] = {
        logout: 'logout',
        breadcrumbs: {
            projects: 'Projects',
            regions: 'Regions',
            find_project_or_brand: 'Find Project or Brand',
            licence_files: 'Licence Files',
            new_licence: 'Add licences',
            batches: 'Activation Codes',
            generate_codes: 'Generate',
            list_pa_batches: 'List batches',
            add_pa_batch: 'Add devices',
            new_pa_batch: 'New batch',
            pa_batches: 'Batches'
        },
        project_detail: {
            licencing: 'Licencing',
            platform: 'Platform',
            app_version: 'Application ver.',
            db_version: 'Database ver.',
            size: 'Size',
            download: 'Download',
            temp_lic_download: 'Get temp licence',
            licenced_devices: 'licenced devices',
            update_policies: 'Update policies',
            no_policies: 'No updates'
        },
        licence_new: {
            id_type: 'Type of device IDs used for licencing',
            licencing: 'Licencing',
            licences_remaining: ' devices left until limit',
            new_licence: 'Create new licence file',
            limit: 'Limit',
            unlimited: 'Unlimited'
        },
        licence_files: {
            today: 'Today',
            yesterday: 'Yesterday',
            find_device_id: 'Find device ID',
            search_too_short: 'You need to type at least 3 characters for the device search to work',
            device: 'device',
            devices: 'devices'
        },
        max_lic: {
            limit_left: 'max',
            limit_right: 'devices allowed',
            unlimited: 'unlimited licences'
        },
        max_codes: {
            limit_left: 'max',
            limit_right: 'codes allowed',
            unlimited: 'unlimited codes'
        },
        ac_project_detail: {
            activation_codes: 'Activation codes',
            issued_codes: ' codes issued',
            app: 'Application',
            product_type: 'Product type',
            partner: 'Partner'
        },
        ac_codes_generate: {
            licencing: 'Licencing',
            activation_codes: 'Activation codes',
            activation_codes_info: 'Used for downloading extra in-app contents',
            amount: 'Amount',
            limit: 'Limit',
            codes_remaining: ' codes left until limit',
            unlimited: 'Unlimited',
            over_codes_limit: 'Exceeded project codes limit of ',
            number_of_codes: 'Number of codes to generate',
            generate: 'Generate'
        },
        ac_batches_list: {
            find_act_code: 'Find activation code',
            search_too_short: 'You need to type at least 3 characters for the search to work',
            code: 'code',
            codes: 'codes'
        },
        ac_batch_show: {
            batch: 'Batch',
            activation_codes: 'Activation codes',
            download_csv: 'Download CSV',
            codes: 'codes',
            author: 'Author'
        },
        pa_project_detail: {
            licencing: 'Licencing',
            app: 'Application',
            partner: 'Partner',
            price_per: 'Price/device',
            total_spent: 'Spent in total'
        },
        pa_batches_list: {
            find_device: 'Find device ID',
            search_too_short: 'You need to type at least 3 characters for the search to work',
            device: 'device',
            devices: 'devices'
        },
        pa_batch_new: {
            id_type: 'Type of device IDs used for licencing',
            licencing: 'Licencing',
            licences_remaining: ' devices left until limit',
            limit: 'Limit',
            unlimited: 'Unlimited'
        },
        device_input_textbox: {
            devices: 'Devices',
            vin_id: 'VIN ID',
            exceeded_max_batch_size: 'Exceeded batch maximum (20000)',
            not_valid_num: 'ID(s) are invalid',
            num_of_ids: 'Number of device ID-s',
            over_licence_limit: 'Exceeded project devices limit of ',
            placeholder_textarea: 'Enter/Paste device-id numbers here',
            placeholder_textarea_vin: 'Enter/Paste VINs here',
            please_wait: 'Please wait',
            submit: 'Submit',
            upload_file: 'Upload File',
            warning: 'WARNING'
        }
    };
});