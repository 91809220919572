define('connect-app/helpers/platform-icon', ['exports', 'ember'], function (exports, _ember) {

	var platform_desc = {
		1: {
			icon: String.fromCharCode(0xe20c),
			name: 'iOS'
		},
		2: {
			icon: String.fromCharCode(0xe20d),
			name: 'Android'
		},
		4: {
			icon: String.fromCharCode(0xe186),
			name: 'Bada'
		},
		8: {
			icon: String.fromCharCode(0xe186),
			name: 'BlackBerry'
		},
		16: {
			icon: String.fromCharCode(0xe20e),
			name: 'WinCE'
		},
		32: {
			icon: String.fromCharCode(0xe20e),
			name: 'Win32'
		},
		64: {
			icon: String.fromCharCode(0xe20c),
			name: 'MacOS'
		},
		128: {
			icon: String.fromCharCode(0xe20f),
			name: 'Linux'
		}
	};

	exports['default'] = _ember['default'].Helper.helper(function (params) {
		var platform = params[0] || params;
		var r = platform_desc[platform.get('platform_id')] || { icon: String.fromCharCode(0xe186), name: 'MULTI' };
		return r.icon;
	});
});