define('connect-app/components/licence-files', ['exports', 'ember', 'connect-app/initializers/model-loader', 'connect-app/libs/utils'], function (exports, _ember, _connectAppInitializersModelLoader, _connectAppLibsUtils) {
	var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

	exports['default'] = _ember['default'].Component.extend({
		project_id: null,
		device_search: '',
		search_too_short: false,

		didReceiveAttrs: function didReceiveAttrs() {
			this._super.apply(this, arguments);
			_connectAppInitializersModelLoader.DB.set('licences.criteria', { 'project_id': this.get('project_id'), 'device_prefix': '' });
		},

		project: (0, _connectAppLibsUtils.computed_model)('Projects', 'project_id'),

		licences: _ember['default'].computed('DB.licences.[]', 'DB.devices.[]', 'project', function () {
			var project = this.get('project');

			if (_.isUndefined(project)) return [];

			var licences = _connectAppInitializersModelLoader.DB.licences.toArray();
			licences.forEach(function (licence) {
				var users = {};

				licence.get('user_info').split(',').forEach(function (pair) {
					var _pair$split = pair.split(':');

					var _pair$split2 = _slicedToArray(_pair$split, 2);

					var user_name = _pair$split2[0];
					var partner_name = _pair$split2[1];

					if (!users[partner_name]) users[partner_name] = [];
					users[partner_name].push(user_name);
				});

				var count = licence.get('device_count');

				var extras = [];

				for (var partner_name in users) {
					extras.push(users[partner_name].join(', ') + ' (' + partner_name + ')');
				}licence.setProperties({
					text_label: (0, _connectAppLibsUtils.human_readable_date)(licence.get('time_generated')) + ' [' + count + ' ' + (0, _connectAppLibsUtils.translate)(count == 1 ? 'licence_files.device' : 'licence_files.devices') + ']',
					extras: extras,
					download_url: 'GetLicence?project_id=' + project.get('project_id') + '&licence_id=' + licence.get('licence_id') + '&series=' + project.get('app_series') + '&sku=' + project.get('app_id')
				});
			});

			return _.sortBy(licences, function (licence) {
				return -licence.get('time_generated');
			});
		}),

		search_observer: _ember['default'].observer('device_search', function () {
			var dev_prefix = this.get('device_search');
			var prev_prefix = _connectAppInitializersModelLoader.DB.licences.get('criteria.device_prefix');

			if (dev_prefix.length > 2 && prev_prefix !== dev_prefix) this._set_prefix(dev_prefix);else if (dev_prefix.length < 3 && prev_prefix !== '') this._set_prefix('');

			this.set('search_too_short', dev_prefix.length > 0 && dev_prefix.length < 3);
		}),

		_set_prefix: function _set_prefix(prefix) {
			_connectAppInitializersModelLoader.DB.licences.set('criteria', { 'project_id': this.get('project_id'), 'device_prefix': prefix });
		},

		actions: {
			click_licence_file: function click_licence_file(licence) {
				(0, _connectAppLibsUtils.download_binary)(licence.download_url);
			}
		}
	});
});