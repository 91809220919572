define('connect-app/components/menu-breadcrumbs', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		service_projects_filter: _ember['default'].inject.service('projects-filter'),

		active_component: null,
		breadcrumbs: [],

		search_input: '',

		options: _ember['default'].computed('active_component', function () {
			return this.get('active_component.options') || {};
		}),

		activate_input_changed: function activate_input_changed() {
			this.get('onSearchInputChanged')(this.get('search_input').toLowerCase());
		},

		actions: {
			search_input_changed: function search_input_changed() {
				_ember['default'].run.debounce(this, this.activate_input_changed, 200);
			},

			project_action: function project_action(component) {
				this.get('router').transitionTo(component, { project_id: this.get('active_component.params.project_id') });
			},

			click_link: function click_link(component, params) {
				if (Object.keys(params).length > 0) this.get('router').transitionTo(component, params);else this.get('router').transitionTo(component);
			}
		}
	});
});