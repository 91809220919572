define('connect-app/components/app-content', ['exports', 'ember', 'connect-app/libs/mireo-data', 'connect-app/initializers/model-loader', 'connect-app/route-config', 'connect-app/libs/utils'], function (exports, _ember, _connectAppLibsMireoData, _connectAppInitializersModelLoader, _connectAppRouteConfig, _connectAppLibsUtils) {
	function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

	function process_display_name(item, model) {
		var FIELD_FUNC_MAP = {
			'translate': function translate(x) {
				return _connectAppLibsUtils.translate(x.translate);
			},
			'raw': function raw(x) {
				return x.raw;
			},
			'model_field': function model_field(x) {
				return !_.isUndefined(model) ? model.get(x.model_field) : null;
			}
		};

		var retval = null;

		for (var field in FIELD_FUNC_MAP) {
			if (_.has(item, field)) {
				retval = FIELD_FUNC_MAP[field](item);
				return _.has(item, 'transform') ? _connectAppLibsUtils[item.transform](retval) : retval;
			}
		}
	}

	exports['default'] = _ember['default'].Component.extend({
		active_component: null,

		breadcrumbs: null,
		precrumbs: null,

		is_user_admin: _connectAppLibsUtils.is_admin(),
		partner_id: _connectAppLibsUtils.get_partner_id(),

		route_change_observer: _ember['default'].on('init', _ember['default'].observer('router.currentRouteName', function () {
			var router = this.get('router');
			var active_component = _.last(router.router.state.handlerInfos);
			var active_component_info = _.cloneDeep(_connectAppRouteConfig['default'][active_component.name]);

			if (_.isUndefined(active_component_info)) {
				console.error('Undefined routing component', active_component.name);
				return [];
			}

			var route_params = _ember['default'].Object.create(router.router.state.params[active_component_info.component]);

			var sections = _.clone(active_component_info.parents);
			sections.push(active_component_info.component);

			var precrumbs = [];
			var watchlist = [];

			sections.forEach(function (section) {
				var component = _.cloneDeep(_connectAppRouteConfig['default'][section]);
				component.params = route_params.getProperties(component.params);

				if (!_.isUndefined(component.model)) watchlist.push(component.model.name);

				if (!_.isUndefined(component.related_models)) watchlist.splice.apply(watchlist, [0, 0].concat(_toConsumableArray(component.related_models)));

				precrumbs.push(_ember['default'].Object.create(component));
			});

			active_component_info.params = precrumbs[precrumbs.length - 1].params;
			this.set('active_component', active_component_info);
			this.set('precrumbs', precrumbs);
			this.set('breadcrumbs', _ember['default'].computed('precrumbs,' + watchlist.map(function (model) {
				return 'DB.' + model.toLowerCase() + '.[]';
			}).join(','), function () {
				var _this = this;

				var breadcrumbs = _.cloneDeep(this.get('precrumbs'));

				breadcrumbs.forEach(function (crumb) {
					var model = !_.isUndefined(crumb.model) ? _connectAppLibsMireoData['default'].table(crumb.model.name).row_by_id(crumb.params[crumb.model.id_field]) : null;
					if (!_.isUndefined(crumb.display_name.projects) && !_.isUndefined(crumb.display_name.regions)) crumb.display_name = _this.get('partner_id') === 17 ? crumb.display_name.regions : crumb.display_name.projects;

					if (!_.isArray(crumb.display_name)) {
						if (_.has(crumb, 'base') && _.has(crumb, 'admin')) {
							crumb.display_name = _this.get('is_user_admin') ? crumb.display_name.admin : crumb.display_name.base;
						} else {
							console.error('Incorrect route display_name specification: ', crumb);
							return;
						}
					}

					crumb.name = crumb.display_name.map(function (x) {
						return process_display_name(x, model);
					}).join(' ') || crumb.component;
				});

				return breadcrumbs;
			}));
		}))
	});
});