define('connect-app/initializers/login', ['exports', 'ember', 'connect-app/libs/auth'], function (exports, _ember, _connectAppLibsAuth) {

	function login(app) {
		app.deferReadiness();

		_connectAppLibsAuth['default'].user_info()['catch'](function (err) {
			// this is just the info; finally below will do the redirect
			console.log(err);
		})['finally'](function () {
			if (_connectAppLibsAuth['default'].get('is_logged') == false) _connectAppLibsAuth['default'].logout();else {
				_connectAppLibsAuth['default'].set('app_origin', app.api_uri || window.location.origin);
				app.advanceReadiness();

				if (!_.isUndefined(_connectAppLibsAuth['default'].get('user.access-from'))) {
					$.ajax({
						type: 'GET',
						url: _connectAppLibsAuth['default'].get('app_origin') + '/AddUser',
						headers: {
							Authorization: 'Bearer ' + _connectAppLibsAuth['default'].get('token')
						}
					});
				}
			}
		});
	}

	exports['default'] = {
		name: 'login',
		initialize: login
	};
});