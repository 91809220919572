define('connect-app/models/schema', ['exports', 'connect-app/libs/mireo-data'], function (exports, _connectAppLibsMireoData) {

	// Projects

	_connectAppLibsMireoData['default'].Schemas.Project = _connectAppLibsMireoData['default'].Model.extend({
		primary_key: ['project_id'],

		project_id: _connectAppLibsMireoData['default'].attr(),
		partner_name: _connectAppLibsMireoData['default'].attr(),
		platform: _connectAppLibsMireoData['default'].attr(),
		name: _connectAppLibsMireoData['default'].attr(),
		project_type: _connectAppLibsMireoData['default'].attr(),
		app_id: _connectAppLibsMireoData['default'].attr(),
		app_series: _connectAppLibsMireoData['default'].attr(),
		app_version: _connectAppLibsMireoData['default'].attr(),
		db_version: _connectAppLibsMireoData['default'].attr(),
		licenced_devices: _connectAppLibsMireoData['default'].attr(),
		max_licences: _connectAppLibsMireoData['default'].attr(),
		download_size: _connectAppLibsMireoData['default'].attr(),
		download_url: _connectAppLibsMireoData['default'].attr(),
		ctag: _connectAppLibsMireoData['default'].attr(),
		opcode: _connectAppLibsMireoData['default'].attr()
	});

	_connectAppLibsMireoData['default'].Tables.Projects = _connectAppLibsMireoData['default'].Table.extend({
		adapter: _connectAppLibsMireoData['default'].StampedAdapter.extend({
			stamp_provider: 'Timestamps',
			url_method: 'ListProjects',
			handle_type: 'fun',
			criteria: Ember.Object.create()
		}),
		row_type: 'Project'
	});

	// ACProject

	_connectAppLibsMireoData['default'].Schemas.ACProject = _connectAppLibsMireoData['default'].Model.extend({
		primary_key: ['project_id'],

		project_id: _connectAppLibsMireoData['default'].attr(),
		project_type: _connectAppLibsMireoData['default'].attr(),
		prod_id: _connectAppLibsMireoData['default'].attr(),
		ext_prod_id: _connectAppLibsMireoData['default'].attr(),
		partner_name: _connectAppLibsMireoData['default'].attr(),
		name: _connectAppLibsMireoData['default'].attr(),
		app_name: _connectAppLibsMireoData['default'].attr(),
		category: _connectAppLibsMireoData['default'].belongs_to('ProductCategories', { aliases: 'category_id' }),
		issued_codes: _connectAppLibsMireoData['default'].attr(),
		max_codes: _connectAppLibsMireoData['default'].attr(),
		opcode: _connectAppLibsMireoData['default'].attr()
	});

	_connectAppLibsMireoData['default'].Tables.ACProjects = _connectAppLibsMireoData['default'].Table.extend({
		adapter: _connectAppLibsMireoData['default'].StampedAdapter.extend({
			stamp_provider: 'Timestamps',
			url_method: 'ListACProjects',
			handle_type: 'fun',
			criteria: Ember.Object.create()
		}),
		row_type: 'ACProject'
	});

	// PAProject

	_connectAppLibsMireoData['default'].Schemas.PAProject = _connectAppLibsMireoData['default'].Model.extend({
		primary_key: ['project_id'],

		project_id: _connectAppLibsMireoData['default'].attr(),
		project_type: _connectAppLibsMireoData['default'].attr(),
		partner_name: _connectAppLibsMireoData['default'].attr(),
		app_series: _connectAppLibsMireoData['default'].attr(),
		name: _connectAppLibsMireoData['default'].attr(),
		app_name: _connectAppLibsMireoData['default'].attr(),
		licenced_devices: _connectAppLibsMireoData['default'].attr(),
		max_devices: _connectAppLibsMireoData['default'].attr(),
		ctag: _connectAppLibsMireoData['default'].attr(),
		price: _connectAppLibsMireoData['default'].attr(),
		price_currency: _connectAppLibsMireoData['default'].attr(),
		opcode: _connectAppLibsMireoData['default'].attr()
	});

	_connectAppLibsMireoData['default'].Tables.PAProjects = _connectAppLibsMireoData['default'].Table.extend({
		adapter: _connectAppLibsMireoData['default'].StampedAdapter.extend({
			stamp_provider: 'Timestamps',
			url_method: 'ListPAProjects',
			handle_type: 'fun',
			criteria: Ember.Object.create()
		}),
		row_type: 'PAProject'
	});

	// UserInfo

	_connectAppLibsMireoData['default'].Schemas.UserInfo = _connectAppLibsMireoData['default'].Model.extend({
		primary_key: ['user_id'],

		user_id: _connectAppLibsMireoData['default'].attr(),
		user_name: _connectAppLibsMireoData['default'].attr(),
		partner_id: _connectAppLibsMireoData['default'].attr(),
		partner_name: _connectAppLibsMireoData['default'].attr(),
		opcode: _connectAppLibsMireoData['default'].attr()
	});

	_connectAppLibsMireoData['default'].Tables.UserInfo = _connectAppLibsMireoData['default'].Table.extend({
		adapter: _connectAppLibsMireoData['default'].StampedAdapter.extend({
			stamp_provider: 'Timestamps',
			url_method: 'GetUserInfo',
			handle_type: 'fun'
		}),
		row_type: 'UserInfo'
	});

	// ProductCategories

	_connectAppLibsMireoData['default'].Schemas.ProductCategory = _connectAppLibsMireoData['default'].Model.extend({
		primary_key: ['category_id'],
		category_id: _connectAppLibsMireoData['default'].attr(),
		category_name: _connectAppLibsMireoData['default'].attr()
	});

	_connectAppLibsMireoData['default'].Tables.ProductCategories = _connectAppLibsMireoData['default'].Table.extend({
		adapter: _connectAppLibsMireoData['default'].ManualAdapter.extend({
			url_method: 'ListProductCategories',
			handle_type: 'fun'
		}),
		row_type: 'ProductCategory'
	});

	// Products

	_connectAppLibsMireoData['default'].Schemas.Product = _connectAppLibsMireoData['default'].Model.extend({
		primary_key: ['product_id'],
		product_id: _connectAppLibsMireoData['default'].attr(),
		project: _connectAppLibsMireoData['default'].belongs_to('Projects', { aliases: 'project_id' }),
		category: _connectAppLibsMireoData['default'].belongs_to('ProductCategories', { aliases: 'category_id' }),
		ref_name: _connectAppLibsMireoData['default'].attr(),
		opcode: _connectAppLibsMireoData['default'].attr()
	});

	_connectAppLibsMireoData['default'].Tables.Products = _connectAppLibsMireoData['default'].Table.extend({
		adapter: _connectAppLibsMireoData['default'].StampedAdapter.extend({
			stamp_provider: 'Timestamps',
			url_method: 'ListProducts',
			handle_type: 'fun',
			rest_args: '{project_id}'
		}),
		row_type: 'Product',
		criteria: Ember.Object.create({ project_id: 0 })
	});

	_connectAppLibsMireoData['default'].Schemas.UpdatePolicy = _connectAppLibsMireoData['default'].Model.extend({
		primary_key: ['project_id', 'policy'],
		project_id: _connectAppLibsMireoData['default'].attr(),
		priority: _connectAppLibsMireoData['default'].attr(),
		paid: _connectAppLibsMireoData['default'].attr(),
		description: _connectAppLibsMireoData['default'].attr(),
		opcode: _connectAppLibsMireoData['default'].attr()
	});

	_connectAppLibsMireoData['default'].Tables.UpdatePolicies = _connectAppLibsMireoData['default'].Table.extend({
		adapter: _connectAppLibsMireoData['default'].StampedAdapter.extend({
			stamp_provider: 'Timestamps',
			url_method: 'ListUpdatePolicies',
			handle_type: 'fun',
			rest_args: '{project_id}'
		}),
		row_type: 'UpdatePolicy',
		criteria: Ember.Object.create({ project_id: 0 })
	});

	// PAProducts

	_connectAppLibsMireoData['default'].Schemas.PAProduct = _connectAppLibsMireoData['default'].Model.extend({
		primary_key: ['product_id'],
		product_id: _connectAppLibsMireoData['default'].attr(),
		project: _connectAppLibsMireoData['default'].belongs_to('PAProjects', { aliases: 'project_id' }),
		category: _connectAppLibsMireoData['default'].belongs_to('ProductCategories', { aliases: 'category_id' }),
		ref_name: _connectAppLibsMireoData['default'].attr(),
		opcode: _connectAppLibsMireoData['default'].attr()
	});

	_connectAppLibsMireoData['default'].Tables.PAProducts = _connectAppLibsMireoData['default'].Table.extend({
		adapter: _connectAppLibsMireoData['default'].StampedAdapter.extend({
			stamp_provider: 'Timestamps',
			url_method: 'ListPAProducts',
			handle_type: 'fun',
			rest_args: '{project_id}'
		}),
		row_type: 'PAProduct',
		criteria: Ember.Object.create({ project_id: 0 })
	});

	// ACFiles

	_connectAppLibsMireoData['default'].Schemas.ACFile = _connectAppLibsMireoData['default'].Model.extend({
		primary_key: ['file_id'],
		file_id: _connectAppLibsMireoData['default'].attr(),
		project: _connectAppLibsMireoData['default'].belongs_to('ACProjects', { aliases: 'project_id' }),
		series: _connectAppLibsMireoData['default'].attr(),
		typeid: _connectAppLibsMireoData['default'].attr(),
		file_type: _connectAppLibsMireoData['default'].attr()
	});

	_connectAppLibsMireoData['default'].Tables.ACFiles = _connectAppLibsMireoData['default'].Table.extend({
		adapter: _connectAppLibsMireoData['default'].ManualAdapter.extend({
			url_method: 'ListACFiles',
			handle_type: 'fun',
			rest_args: '{project_id}'
		}),
		row_type: 'ACFile',
		criteria: Ember.Object.create({ project_id: 0 })
	});

	// Licences

	_connectAppLibsMireoData['default'].Schemas.Licence = _connectAppLibsMireoData['default'].Model.extend({
		primary_key: ['licence_id'],
		licence_id: _connectAppLibsMireoData['default'].attr(),
		project: _connectAppLibsMireoData['default'].belongs_to('Projects', { aliases: 'project_id' }),
		user_info: _connectAppLibsMireoData['default'].attr(),
		time_generated: _connectAppLibsMireoData['default'].attr(),
		device_count: _connectAppLibsMireoData['default'].attr(),
		opcode: _connectAppLibsMireoData['default'].attr()
	});

	_connectAppLibsMireoData['default'].Tables.Licences = _connectAppLibsMireoData['default'].Table.extend({
		adapter: _connectAppLibsMireoData['default'].StampedAdapter.extend({
			stamp_provider: 'Timestamps',
			url_method: 'ListLicences',
			handle_type: 'fun',
			rest_args: '{project_id}&{device_prefix}'
		}),
		row_type: 'Licence',
		criteria: Ember.Object.create({ project_id: 0, device_prefix: '' })
	});

	// ACBatches

	_connectAppLibsMireoData['default'].Schemas.ACBatch = _connectAppLibsMireoData['default'].Model.extend({
		primary_key: ['batch_id'],
		batch_id: _connectAppLibsMireoData['default'].attr(),
		project: _connectAppLibsMireoData['default'].belongs_to('ACProjects', { aliases: 'project_id' }),
		user_name: _connectAppLibsMireoData['default'].attr(),
		partner_name: _connectAppLibsMireoData['default'].attr(),
		time_generated: _connectAppLibsMireoData['default'].attr(),
		issued_codes: _connectAppLibsMireoData['default'].attr(),
		opcode: _connectAppLibsMireoData['default'].attr()
	});

	_connectAppLibsMireoData['default'].Tables.ACBatches = _connectAppLibsMireoData['default'].Table.extend({
		adapter: _connectAppLibsMireoData['default'].StampedAdapter.extend({
			stamp_provider: 'Timestamps',
			url_method: 'ListACBatches',
			handle_type: 'fun',
			rest_args: '{project_id}&{code_prefix}'
		}),
		row_type: 'ACBatch',
		criteria: Ember.Object.create({ project_id: 0, code_prefix: '' })
	});

	// ACCodes

	_connectAppLibsMireoData['default'].Schemas.ACCode = _connectAppLibsMireoData['default'].Model.extend({
		primary_key: ['activation_code'],
		activation_code: _connectAppLibsMireoData['default'].attr(),
		batch_id: _connectAppLibsMireoData['default'].attr()
	});

	_connectAppLibsMireoData['default'].Tables.ACCodes = _connectAppLibsMireoData['default'].Table.extend({
		adapter: _connectAppLibsMireoData['default'].ManualAdapter.extend({
			url_method: 'GetACCodes',
			handle_type: 'fun',
			rest_args: '{project_id}&{batch_id}'
		}),
		row_type: 'ACCode',
		criteria: Ember.Object.create({ project_id: 0, batch_id: 0 })
	});

	// PABatches

	_connectAppLibsMireoData['default'].Schemas.PABatch = _connectAppLibsMireoData['default'].Model.extend({
		primary_key: ['batch_id'],
		batch_id: _connectAppLibsMireoData['default'].attr(),
		project: _connectAppLibsMireoData['default'].belongs_to('PAProjects', { aliases: 'project_id' }),
		user_name: _connectAppLibsMireoData['default'].attr(),
		partner_name: _connectAppLibsMireoData['default'].attr(),
		time_generated: _connectAppLibsMireoData['default'].attr(),
		licenced_devices: _connectAppLibsMireoData['default'].attr(),
		opcode: _connectAppLibsMireoData['default'].attr()
	});

	_connectAppLibsMireoData['default'].Tables.PABatches = _connectAppLibsMireoData['default'].Table.extend({
		adapter: _connectAppLibsMireoData['default'].StampedAdapter.extend({
			stamp_provider: 'Timestamps',
			url_method: 'ListPABatches',
			handle_type: 'fun',
			rest_args: '{project_id}&{device_prefix}'
		}),
		row_type: 'PABatch',
		criteria: Ember.Object.create({ project_id: 0, device_prefix: '' })
	});

	// Timestamps

	_connectAppLibsMireoData['default'].Tables.Timestamps = _connectAppLibsMireoData['default'].Table.extend({
		adapter: _connectAppLibsMireoData['default'].PeriodicAdapter.extend({
			interval: 2500,
			handle_type: 'fun',
			url_method: 'GetFunctionStamps',
			rest_args: '{stamp}'
		}),
		criteria: Ember.Object.create({ stamp: 0 }),

		reload: function reload(json) {
			var _this = this;

			if (!Ember.isArray(json)) return;
			var stamp = this.get('criteria.stamp');
			_.each(json, function (ob) {
				_this.set(ob['function_name'], ob.stamp);
				if (stamp < ob.stamp) stamp = ob.stamp;
			});
			if (stamp > this.get('criteria.stamp')) this.set('criteria.stamp', stamp);
		}
	});
});