define('connect-app/route-config', ['exports'], function (exports) {
    exports['default'] = {
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        //  SPECIFICATION
        //  'component-name': {
        //      component: 'component-name',
        //      url: 'component-url-section',
        //      params: ['param1_name', 'param2_name', ...],
        //      [model]: {
        //          'name': 'model-name',
        //          'id_field': 'model-id'
        //      }
        //      [parents]: [list-of-parent-components],
        //      display_name: [ // could also be an object with 'base' and 'admin' keys (having array values)
        //                      // to differentiate between admin and regular user display
        //          {   // contains one of the properties listed below
        //              [name]: 'plain-string',
        //              [translate]: 'translate-string-id',
        //              [model_field]: 'model-field-name,
        //
        //              // optional function for transforming given value (one of a predefined set)
        //              [transform]: 'function_name'
        //          }, [...]
        //      ]
        //      [options]: {
        //          // can contain some or none of the below
        //          [show_search]: true,
        //          [show_licences_btn]: true,
        //          [show_new_licence_btn]: true,
        //          [show_activation_codes]: true,
        //          [show_generate_codes]: true
        //      }
        //  }
        ////////////////////////////////////////////////////////////////////////////////////////////////////

        'application': {
            component: 'projects',
            url: 'projects',
            display_name: [{ translate: 'breadcrumbs.projects' }],
            options: {
                show_search: true
            }
        },
        'projects': {
            component: 'projects',
            url: 'projects',
            parents: [],
            display_name: {
                projects: [{ translate: 'breadcrumbs.projects' }],
                regions: [{ translate: 'breadcrumbs.regions' }]
            },
            options: {
                show_search: true
            }
        },
        'project-detail': {
            component: 'project-detail',
            url: ':project_id',
            params: ['project_id'],
            model: {
                name: 'Projects',
                id_field: 'project_id'
            },
            parents: ['projects'],
            display_name: [{ model_field: 'name' }],
            options: {
                show_licences_btn: true,
                show_new_licence_btn: true
            }
        },
        'licence-new': {
            component: 'licence-new',
            url: 'new-licence',
            parents: ['projects', 'project-detail'],
            display_name: [{ translate: 'breadcrumbs.new_licence' }]
        },
        'licence-files': {
            component: 'licence-files',
            url: 'licences',
            parents: ['projects', 'project-detail'],
            display_name: [{ translate: 'breadcrumbs.licence_files' }],
            options: {
                show_new_licence_btn: true
            }
        },
        'ac-project-detail': {
            component: 'ac-project-detail',
            url: 'act-code/:project_id',
            params: ['project_id'],
            model: {
                name: 'ACProjects',
                id_field: 'project_id'
            },
            parents: ['projects'],
            display_name: [{ model_field: 'name' }],
            options: {
                show_activation_codes: true,
                show_generate_codes: true
            }
        },
        'ac-batches-list': {
            component: 'ac-batches-list',
            url: 'codes',
            parents: ['projects', 'ac-project-detail'],
            display_name: [{ translate: 'breadcrumbs.batches' }],
            options: {
                show_generate_codes: true
            }
        },
        'ac-codes-generate': {
            component: 'ac-codes-generate',
            url: 'generate-codes',
            parents: ['projects', 'ac-project-detail'],
            display_name: [{ translate: 'breadcrumbs.generate_codes' }]
        },
        'ac-batch-show': {
            component: 'ac-batch-show',
            url: ':batch_id',
            params: ['batch_id'],
            model: {
                name: 'ACBatches',
                id_field: 'batch_id'
            },
            parents: ['projects', 'ac-project-detail', 'ac-batches-list'],
            display_name: [{ model_field: 'time_generated', transform: 'datetime_format' }]
        },
        'pa-project-detail': {
            component: 'pa-project-detail',
            url: 'preactivated/:project_id',
            params: ['project_id'],
            model: {
                name: 'PAProjects',
                id_field: 'project_id'
            },
            parents: ['projects'],
            display_name: [{ model_field: 'name' }],
            options: {
                show_preactivated_batches: true,
                show_add_preactivated_batch: true
            }
        },
        'pa-batches-list': {
            component: 'pa-batches-list',
            url: 'batches',
            parents: ['projects', 'pa-project-detail'],
            display_name: [{ translate: 'breadcrumbs.pa_batches' }],
            options: {
                show_add_preactivated_batch: true
            }
        },
        'pa-batch-new': {
            component: 'pa-batch-new',
            url: 'new-batch',
            parents: ['projects', 'pa-project-detail'],
            display_name: [{ translate: 'breadcrumbs.new_pa_batch' }]
        }
    };
});