define('connect-app/components/project-detail', ['exports', 'ember', 'connect-app/initializers/model-loader', 'connect-app/libs/utils'], function (exports, _ember, _connectAppInitializersModelLoader, _connectAppLibsUtils) {
	exports['default'] = _ember['default'].Component.extend({
		project_id: null,

		didReceiveAttrs: function didReceiveAttrs() {
			this._super.apply(this, arguments);
			_connectAppInitializersModelLoader.DB.set('products.criteria', { project_id: this.get('project_id') });
			_connectAppInitializersModelLoader.DB.set('updatepolicies.criteria', { project_id: this.get('project_id') });
		},

		project_data: (0, _connectAppLibsUtils.computed_model)('Projects', 'project_id'),
		partner_id: (0, _connectAppLibsUtils.get_partner_id)(),

		content: _ember['default'].computed('DB.products.[]', 'DB.productcategories.[]', 'DB.projects.[]', function () {
			if (_connectAppInitializersModelLoader.DB.productcategories.length == 0) return [];

			var content = [];
			_connectAppInitializersModelLoader.DB.products.forEach(function (product) {
				var category_id = product.get('category.category_id');
				var category_name = product.get('category.category_name');

				if (_.isUndefined(content[category_id])) content[category_id] = { name: category_name, items: [] };

				content[category_id].items.pushObject(product.get('ref_name'));
			});

			return content;
		}),

		policies: _ember['default'].computed('DB.updatepolicies.[]', function () {
			return _connectAppInitializersModelLoader.DB.updatepolicies.toArray();
		}),

		actions: {
			download_temp_lic: function download_temp_lic() {
				(0, _connectAppLibsUtils.download_binary)('GetTempLicence?project_id=' + this.get('project_id') + '&series=' + this.get('project_data.app_series') + '&sku=' + this.get('project_data.app_id'));
			}
		}
	});
});