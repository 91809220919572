define('connect-app/services/projects-filter', ['exports', 'ember'], function (exports, _ember) {

    function tokenize(str) {
        if (_.isUndefined(str)) return [];
        return str.split(/(?:-|_|:|\s)+/g);
    }

    exports['default'] = _ember['default'].Service.extend({
        search_terms: '',
        search_tokens: [],

        search_tokens_updater: _ember['default'].observer('search_terms', function () {
            var terms = this.get('search_terms');
            this.set('search_tokens', tokenize(terms).map(function (x) {
                return x.toLowerCase();
            }));
        }),

        is_match: function is_match(project, type) {
            var source = type == 1 ? [project.get('name'), project.get('partner_name'), project.get('app_series')] : type == 2 ? [project.get('name'), project.get('partner_name'), 'Activation codes'] : [project.get('name'), project.get('partner_name'), project.get('app_series'), 'Preactivated products'];
            var tokens = _.flatten(source.map(tokenize));

            var bag = tokens.map(function (x) {
                return x.toLowerCase();
            });
            return _.all(this.get('search_tokens').map(function (token) {
                return bag.some(function (x) {
                    return x.includes(token);
                });
            }));
        }
    });
});