define("connect-app/templates/components/licence-files", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 4
            },
            "end": {
              "line": 5,
              "column": 4
            }
          },
          "moduleName": "connect-app/templates/components/licence-files.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "t", ["licence_files.search_too_short"], [], ["loc", [null, [4, 8], [4, 46]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 0
            },
            "end": {
              "line": 10,
              "column": 0
            }
          },
          "moduleName": "connect-app/templates/components/licence-files.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "icon-list-item", [], ["item", ["subexpr", "@mut", [["get", "licence", ["loc", [null, [9, 26], [9, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "icon", "i_save", "onItemClicked", ["subexpr", "action", ["click_licence_file", ["get", "licence", ["loc", [null, [9, 91], [9, 98]]], 0, 0, 0, 0]], [], ["loc", [null, [9, 62], [9, 99]]], 0, 0], "label", ["subexpr", "@mut", [["get", "licence.text_label", ["loc", [null, [9, 106], [9, 124]]], 0, 0, 0, 0]], [], [], 0, 0], "extras", ["subexpr", "@mut", [["get", "licence.extras", ["loc", [null, [9, 132], [9, 146]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [9, 4], [9, 148]]], 0, 0]],
        locals: ["licence"],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 11,
            "column": 0
          }
        },
        "moduleName": "connect-app/templates/components/licence-files.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "height-5 padding-left-10 color-bg2");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(element0, 1, 1);
        morphs[1] = dom.createMorphAt(element0, 3, 3);
        morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "input", [], ["value", ["subexpr", "@mut", [["get", "device_search", ["loc", [null, [2, 18], [2, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "rounded-button font-size-small padding-8 padding-search", "placeholder", ["subexpr", "t", ["licence_files.find_device_id"], [], ["loc", [null, [2, 108], [2, 142]]], 0, 0]], ["loc", [null, [2, 4], [2, 144]]], 0, 0], ["block", "if", [["get", "search_too_short", ["loc", [null, [3, 10], [3, 26]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [3, 4], [5, 11]]]], ["block", "each", [["get", "licences", ["loc", [null, [8, 8], [8, 16]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [8, 0], [10, 9]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});