define("connect-app/templates/components/projects-list", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 3,
              "column": 0
            }
          },
          "moduleName": "connect-app/templates/components/projects-list.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("	");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "icon-list-item", [], ["item", ["subexpr", "@mut", [["get", "project", ["loc", [null, [2, 23], [2, 30]]], 0, 0, 0, 0]], [], [], 0, 0], "onItemClicked", ["subexpr", "action", ["project_selected", ["get", "project", ["loc", [null, [2, 72], [2, 79]]], 0, 0, 0, 0]], [], ["loc", [null, [2, 45], [2, 80]]], 0, 0], "label", ["subexpr", "@mut", [["get", "project.text_label", ["loc", [null, [2, 87], [2, 105]]], 0, 0, 0, 0]], [], [], 0, 0], "extras", ["subexpr", "@mut", [["get", "project.extras", ["loc", [null, [2, 113], [2, 127]]], 0, 0, 0, 0]], [], [], 0, 0], "icon", ["subexpr", "@mut", [["get", "project.icon", ["loc", [null, [2, 133], [2, 145]]], 0, 0, 0, 0]], [], [], 0, 0], "colorful", false], ["loc", [null, [2, 1], [2, 162]]], 0, 0]],
        locals: ["project"],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 4,
            "column": 0
          }
        },
        "moduleName": "connect-app/templates/components/projects-list.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "each", [["get", "projects", ["loc", [null, [1, 8], [1, 16]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [1, 0], [3, 9]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});