define('connect-app/components/ac-codes-generate', ['exports', 'ember', 'connect-app/libs/auth', 'connect-app/libs/utils'], function (exports, _ember, _connectAppLibsAuth, _connectAppLibsUtils) {
    exports['default'] = _ember['default'].Component.extend({
        project_id: null,

        UNLIMITED_CODES: Number.MAX_SAFE_INTEGER,

        num_codes: '',

        valid_num_codes: _ember['default'].computed.match('num_codes', /^\d*$/),
        submit_allowed: _ember['default'].computed.and('num_codes', 'valid_num_codes', 'under_limit'),
        submit_in_progress: false,

        project: (0, _connectAppLibsUtils.computed_model)('ACProjects', 'project_id'),

        maximum_codes: _ember['default'].computed('project', function () {
            var max_codes = this.get('project.max_codes');
            return max_codes < 0 ? Number.MAX_SAFE_INTEGER : max_codes - this.get('project.issued_codes');
        }),

        under_limit: _ember['default'].computed('num_codes', 'maximum_codes', function () {
            return this.get('num_codes') <= this.get('maximum_codes');
        }),

        jump_to_batch: function jump_to_batch(batch_id) {
            this.get('router').transitionTo('ac-batch-show', { project_id: this.get('project_id'), batch_id: batch_id });
        },

        actions: {
            generate_codes: function generate_codes() {
                var _this = this;

                if (!this.get('submit_allowed') || this.get('submit_in_progress')) return;

                var url = _connectAppLibsAuth['default'].get('app_origin') + '/GenerateACCodes?project_id=' + this.get('project_id') + '&num_codes=' + this.get('num_codes');

                this.set('submit_in_progress', true);

                _connectAppLibsAuth['default'].refresh_token().then(function () {
                    $.ajax({
                        type: 'POST',
                        url: url,
                        headers: {
                            Authorization: 'Bearer ' + _connectAppLibsAuth['default'].get('token')
                        },
                        success: function success(msg) {
                            if (msg.batch_id == 0) {
                                alert('Error: Couldn\'t generate activation codes.');
                            } else {
                                _this.set('num_codes', '');
                                _ember['default'].run.later(_this, function () {
                                    return _this.jump_to_batch(msg.batch_id);
                                }, 250);
                            }
                        },
                        error: function error(jqXHR, textStatus, errorThrown) {
                            return console.error(textStatus, errorThrown);
                        },
                        complete: function complete(xhr, status) {
                            return _this.set('submit_in_progress', false);
                        }
                    });
                })['catch'](function (err) {
                    return _this.set('submit_in_progress', false);
                });
            }
        }
    });
});