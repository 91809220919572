define('connect-app/components/ac-project-detail', ['exports', 'ember', 'connect-app/initializers/model-loader', 'connect-app/libs/utils'], function (exports, _ember, _connectAppInitializersModelLoader, _connectAppLibsUtils) {
    exports['default'] = _ember['default'].Component.extend({
        project_id: null,

        didReceiveAttrs: function didReceiveAttrs() {
            this._super.apply(this, arguments);
            _connectAppInitializersModelLoader.DB.set('acfiles.criteria', { "project_id": this.get('project_id') });
        },

        project_data: (0, _connectAppLibsUtils.computed_model)('ACProjects', 'project_id'),

        content: _ember['default'].computed('DB.acfiles.[]', 'DB.acprojects.[]', function () {
            var content = [];

            _connectAppInitializersModelLoader.DB.acfiles.forEach(function (file) {
                var _file$getProperties = file.getProperties(['typeid', 'file_type']);

                var typeid = _file$getProperties.typeid;
                var file_type = _file$getProperties.file_type;

                if (_.isUndefined(content[typeid])) content[typeid] = { name: file_type.capitalize(), items: [] };

                content[typeid].items.pushObject(file.get('series'));
            });

            return content;
        })
    });
});