define('connect-app/components/ac-batches-list', ['exports', 'ember', 'connect-app/initializers/model-loader', 'connect-app/libs/utils'], function (exports, _ember, _connectAppInitializersModelLoader, _connectAppLibsUtils) {
    exports['default'] = _ember['default'].Component.extend({
        project_id: null,
        act_code_search: '',
        search_too_short: '',

        didReceiveAttrs: function didReceiveAttrs() {
            this._super.apply(this, arguments);
            _connectAppInitializersModelLoader.DB.set('acbatches.criteria', { 'project_id': this.get('project_id'), 'code_prefix': '' });
        },

        batches: _ember['default'].computed('DB.acbatches.[]', function () {
            var batches = _connectAppInitializersModelLoader.DB.acbatches.toArray();
            batches.forEach(function (batch) {
                var num_codes = batch.get('issued_codes');

                batch.setProperties({
                    text_label: (0, _connectAppLibsUtils.datetime_format)(batch.get('time_generated')) + ' [' + num_codes + ' ' + (0, _connectAppLibsUtils.translate)(num_codes == 1 ? 'ac_batches_list.code' : 'ac_batches_list.codes') + ']',
                    extras: batch.get('user_name') + ' (' + batch.get('partner_name') + ')',
                    icon: 'i_doc_full'
                });
            });

            return _.sortBy(batches, function (batch) {
                return -batch.get('time_generated');
            });
        }),

        search_observer: _ember['default'].observer('act_code_search', function () {
            var code_prefix = this.get('act_code_search');
            var prev_prefix = _connectAppInitializersModelLoader.DB.acbatches.get('criteria.code_prefix');

            if (code_prefix.length > 2 && prev_prefix !== code_prefix) this._set_prefix(code_prefix);else if (code_prefix.length < 3 && prev_prefix !== '') this._set_prefix('');

            this.set('search_too_short', code_prefix.length > 0 && code_prefix.length < 3);
        }),

        _set_prefix: function _set_prefix(prefix) {
            _connectAppInitializersModelLoader.DB.acbatches.set('criteria', { 'project_id': this.get('project_id'), 'code_prefix': prefix });
        },

        actions: {
            click_batch: function click_batch(batch_id) {
                this.get('router').transitionTo('ac-batch-show', { project_id: this.get('project_id'), batch_id: batch_id });
            }
        }
    });
});